import { EditTwoTone } from "@ant-design/icons";
import { apiStatuses } from "@/store/api/constants";
import { IconButton, Stack, Tooltip } from "@mui/material";
import PropTypes from 'prop-types';

function EditAction({row, onRowEdit, deleteShiftMasterStatus}) {
    const {original: {shiftId}} = row; 
    return (
    <Stack direction="row" spacing={1} alignItems="center">
        <Tooltip title="Edit">
            <IconButton
                color="primary"
                onClick={() => onRowEdit(shiftId) }
                disabled={deleteShiftMasterStatus === apiStatuses.pending}
            >
                <EditTwoTone />
            </IconButton>
        </Tooltip>
    </Stack>
    );
}

EditAction.propTypes = {
    row: PropTypes.object,
    table: PropTypes.object,
    options: PropTypes.array,
    id: PropTypes.number,
    index: PropTypes.number
};
export default EditAction;
