const signinJSON = require("/public/mock-responses/signin.json");

// below function will do partial mocking
export const getResponseInterceptor = (response) => {
    const results = JSON.parse(JSON.stringify(response));
    if(response?.data?.response?.data) {
        // START login and authenticate API
        if(
            (
                (response?.config?.url || "").includes("api/authenticate")
                || (response?.config?.url || "").includes("api/login")
            )
            && response.data.response.data?.entitlement === undefined
        ) {
            results.data.response.data = {
                ...results.data.response.data,
                entitlement: signinJSON.response.data.entitlement
            }
        }
        // END login and authenticate API
    }
    return results;
}