import React from "react";
import {
  Button, DialogActions, DialogContent, Grid
} from '@mui/material';
import TextInput from "@/common/components/text-input/text-input";
import SwitchInput from "@/common/components/switch-input/switch-input";
import AnimateButton from "@/from-theme/components/@extended/AnimateButton";
import { FormattedMessage } from 'react-intl';
import { apiStatuses } from "@/store/api/constants";
import useCreateEditEventGroups from "./hooks/useCreateEditEventGroups";
import Spinner from "@/common/components/spinner/spinner";
import ErrorInPage from "@/common/components/error-in-page/error-in-page";
import PropTypes from "prop-types";


function CreateEditZoneMaster({
  closeModal, activeEditId
}) {

  const { values, formik,getEditZoneMasterStatus, saveZoneMasterStatus, updateZoneMasterStatus,
    getEditZoneMasterErrMsg } = useCreateEditEventGroups(activeEditId);

  const isPending = [saveZoneMasterStatus, updateZoneMasterStatus].includes(apiStatuses.pending);
  const {
    groupName, stages, rules, offers, activeStatus
  } = values;

  return (
    <>
    {
      getEditZoneMasterStatus === apiStatuses.pending && activeEditId
      && <Spinner />
    }
    {getEditZoneMasterStatus === apiStatuses.rejected && activeEditId && (
      <ErrorInPage message={getEditZoneMasterErrMsg} />
    )}
    {(getEditZoneMasterStatus === apiStatuses.succeeded || !activeEditId) &&
    <form onSubmit={formik.handleSubmit}>
      <DialogContent dividers>
        <Grid container spacing={1.25} >
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <TextInput
              placeholder="group-name" size="small" fullWidth
              value={groupName}
              inputLabel="group-name"
              formik={formik}
              name="groupName"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <TextInput
              placeholder="stages" size="small" fullWidth
              value={stages}
              inputLabel="stages"
              formik={formik}
              name="stages"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <TextInput
              placeholder="rules" size="small" fullWidth
              value={rules}
              inputLabel="rules"
              formik={formik}
              name="rules"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <TextInput
              placeholder="offers" size="small" fullWidth
              value={offers}
              inputLabel="offers"
              formik={formik}
              name="offers"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <SwitchInput
              inputLabel="activeStatus"
              leftLabel="inactive"
              rightLabel="active"
              value={activeStatus}
              formik={formik}
              name="activeStatus"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={closeModal} variant="outlined" disabled={isPending}
         sx={{ mr: 1 }}>
          <FormattedMessage id={"cancel"} />
        </Button>
        <AnimateButton>
          <Button type="submit" variant="contained" disabled={isPending} sx={{ mr: 1 }}>
            <FormattedMessage id={"save"} />
          </Button>
        </AnimateButton>
      </DialogActions>
    </form>
    }
    </>
  )
}

CreateEditZoneMaster.propTypes = {
  closeModal: PropTypes.func.isRequired,
  activeEditId: PropTypes.bool,
};

CreateEditZoneMaster.defaultProps = {
  activeEditId: 0,
};

export default CreateEditZoneMaster;