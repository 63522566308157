import { BookOutlined, UserOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import Brand from '../components/brand/brand';
import Category from '../components/category/category';
import Product from '../components/product/product';
import Material from '../components/material/material';

import { requestedFeaturesAll, requestedMenuAll } 
from '../../../../from-theme/routes/constants/menuAccess';
import VerifyAccess from '@/common/components/verify-access/verify-access';

const { brandTabMasterKey, categoryTabMasterKey, productTabMasterKey, 
    materialTabMasterKey } = requestedMenuAll;
const { readKey } = requestedFeaturesAll;

// tabName is locale id
export const productMasterTabs = [
  {
    id: 'brand',
    tabName: 'brand',
    icon: <UserOutlined />,
    tabContent: (
      <VerifyAccess requestedMenu={brandTabMasterKey} requestedFeature={readKey}>
        <Brand />
      </VerifyAccess>
    )
  },
  {
    id: 'category',
    tabName: 'category',
    icon: <BookOutlined />,
    tabContent: (
      <VerifyAccess requestedMenu={categoryTabMasterKey} requestedFeature={readKey}>
        <Category />
      </VerifyAccess>
    )
  },
  {
    id: 'product',
    tabName: 'product',
    icon: <UsergroupAddOutlined />,
    tabContent: (
      <VerifyAccess requestedMenu={productTabMasterKey} requestedFeature={readKey}>
        <Product />
      </VerifyAccess>
    )
  },
  {
    id: 'material',
    tabName: 'material',
    icon: <UsergroupAddOutlined />,
    tabContent: (
      <VerifyAccess requestedMenu={materialTabMasterKey} requestedFeature={readKey}>
        <Material />
      </VerifyAccess>
    )
  }
];
