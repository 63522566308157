import { lazy } from 'react';

// project import
import Loadable from '@/from-theme/components/Loadable';
import PublicLayout from '../layout/Public';

// render - login
const Terms = Loadable(lazy(() => import('pages/common/terms/terms')));
const Policy = Loadable(lazy(() => import('pages/common/policy/policy')));
const Deactivate = Loadable(lazy(() => import('pages/common/deactivate/deactivate')));

// ==============================|| AUTH ROUTING ||============================== //

const PublicRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: <PublicLayout />,
      children: [
        {
          path: 'terms',
          element: <Terms />
        },
        {
          path: 'policy',
          element: <Policy />
        },
        {
          path: 'deactivate',
          element: <Deactivate />
        }
        
      ]
    }
  ]
};

export default PublicRoutes;
