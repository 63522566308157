import { 
  Autocomplete, Checkbox, InputLabel,
  TextField, createFilterOptions
} from '@mui/material';
import { makeId } from '@/common/helpers/helpers';
import './dropdown.scss';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';



function Dropdown({
  options, onChange, placeholder = '', label = '', size = 'small', loading = false,
  multiple = false, inputLabel = "", value="", name="",
  formik=null, isCheckBox = false, disabled = false, ...rest
}) {
  const {formatMessage} = useIntl();

  // let valueUpdated = (Object.keys(value || {}).length === 0) 
  //   ? "" : value;
  // valueUpdated = multiple && !valueUpdated ? [] : value;

  let valueUpdated = value || "";
  if(value && typeof value === "object" && !Array.isArray(value) &&
   Object.keys(value).length === 0 ){
    valueUpdated = "";
  } else if(multiple && !value){
    valueUpdated = [];
  }

  return (
    <> 
      {!!inputLabel && 
        <InputLabel className='labelClr' required={!formik?.fields[name]?.spec?.optional}>
          <FormattedMessage id={inputLabel} />
        </InputLabel>
      }
      <Autocomplete
      filterOptions={(options, params) => {
        const filter = createFilterOptions();
        const filtered = filter(options, params);
        if (multiple && options.length > 0) {
          return [{ label: formatMessage({id: "Select All"}), all: true }, ...filtered];
        } else {
          return filtered;
        }
      }}
      
        style={{ width: '100%' }}
        placement='top-start'
        multiple={multiple}
        limitTags={1}
        size={size || 'small'}
        fullWidth
        disablePortal
        id={`autocomplete_${makeId()}`}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={placeholder ? formatMessage({id: placeholder}) : ""}
            label={label}
            error={formik?.touched[name] && Boolean(formik?.errors[name])}
            helperText={formik?.touched[name] && formik?.errors[name]}
          />
        )}
        onChange={(event, newValueRaw) => {
          let newValue = JSON.parse(JSON.stringify(newValueRaw));
          if(isCheckBox && multiple && newValue.find(option => option.all)) {
            newValue = (value?.length === options.length ? [] : options)
          }
          if (formik) {
            formik.setFieldValue(name, newValue);
            onChange(value, event)
          } else {
             onChange(value, event)
          }
        }}
        value={valueUpdated}
        disabled={disabled}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        {...(isCheckBox ? {
          renderOption: (props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                style={{ marginRight: 8 }}
                checked={option.all ? !!(value?.length === options.length) : selected}
              />
              {option.label}
            </li>
          ),
          disableCloseOnSelect: true,
        } : {})}
        {...(rest || {})}
      />
    </>
  );
}

Dropdown.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  inputLabel: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string,
  formik: PropTypes.object,
  isCheckBox: PropTypes.bool,
  disabled: PropTypes.bool
}

Dropdown.defaultProps = {
  placeholder: "",
  label: "",
  size: "",
  loading: "",
  multiple: false,
  inputLabel: "",
  value: null,
  formik: null,
  name: "",
  onChange: () => {},
  isCheckBox: false,
  disabled: false
}

export default Dropdown;
