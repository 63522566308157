import TableView from '@/common/components/TableView/TableViewWithoutDownload';
import { Grid, Button } from '@mui/material';
import React from 'react';
import useTarget from './hooks/useTarget';
import { columns, reportFileName } from './constants/constants';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import useOnActions from './hooks/useOnActions';
import AlertDialog from '@/common/components/alert-dialog/alert-dialog';
import { requestedMenuAll, requestedFeaturesAll } from '@/from-theme/routes/constants/menuAccess';
import { isUserHasAccess } from '@/from-theme/utils/route-guard/helpers/helpers';
import useAuth from '@/from-theme/hooks/useAuth';
import MainCard from '@/from-theme/components/MainCard';
import MonthYearInput from '@/common/components/date-input/monthYear-input';

const { targetKey } = requestedMenuAll;
const { updateKey, deleteKey } = requestedFeaturesAll;

const Target = () => {
  const { values, formik, handleUploadCSV, handleDownloadCSV, handleFileChange, 
    shiftMasterData, shiftMasterStatus, onSearch,
    shiftMasterErrMsg, selectedFile } = useTarget();
  const { userData } = useAuth();
  const entitlement = userData?.entitlement || {};

  const { selectedMonthYear } = values;

  const isUpdateDeleteAccess = isUserHasAccess(targetKey, updateKey, entitlement) || 
  isUserHasAccess(targetKey, deleteKey, entitlement);
  const { onRowEdit, onRowDelete, deleteShiftMasterStatus, isAlertDialog, onCloseAlert,
     onAgreeAlert } = useOnActions();

  return (
    <MainCard>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <MonthYearInput
            name="selectedMonthYear"
            inputLabel="Month and Year"
            placeholder="Select Month and Year"
            value={selectedMonthYear}
            formik={formik}
            sx={{
              '& .Mui-disabled': {
                color: '#000',
                opacity: 1
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={1} style={{ paddingTop: '45px' }}>
          <Button type="submit" variant="contained" size="medium" onClick={onSearch}>
            <FormattedMessage id="search" />
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ paddingTop: '55px' }}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Button type="submit" color='warning' variant="contained" 
          size="large" onClick={handleDownloadCSV}>
          <DownloadOutlined style={{ marginRight: 8 }} />
          <span>Sample Excel</span>
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={6}>
          <input type="file" accept=".csv,.xlsx" onChange={handleFileChange} 
          style={{ display: 'none' }} id="file-upload" />
          <label htmlFor="file-upload">
            <Button
              component="span"
              variant="contained"
              color='info'
              size="large"
              style={{ backgroundColor: selectedFile ? '#32a88f' : '' }}
            >
              <UploadOutlined style={{ marginRight: 8 }} /> 
              {selectedFile ? 'File Selected' : 'Select File'}
            </Button>
          </label>

          {selectedFile && (
            <>
              <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>{selectedFile.name}</span>
              <Button variant="contained" size="large" onClick={handleUploadCSV}
               style={{ marginLeft: '10px' }}>
                Upload
              </Button>
            </>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{ paddingTop: '55px' }}>
        <Grid item xs={12}>
          <TableView
            data={shiftMasterData}
            columns={columns({ onRowEdit, onRowDelete, deleteShiftMasterStatus, 
              isUpdateDeleteAccess })}
            reportFileName={reportFileName}
            apiStatus={shiftMasterStatus}
            apiErrorMsg={shiftMasterErrMsg}
            stringInExport={['activeStatus']}
          />
          {isAlertDialog && <AlertDialog onCloseAlert={onCloseAlert} onAgreeAlert={onAgreeAlert} />}
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default Target;
