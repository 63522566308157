import { Chip, Grid } from "@mui/material";
import EditAction from "../components/edit-action/edit-action";
import DeleteAction from "../components/delete-action/delete-action";
import VerifyAccess from "@/common/components/verify-access/verify-access";
import { requestedMenuAll, requestedFeaturesAll } from "@/from-theme/routes/constants/menuAccess";
import { dateString } from "@/common/components/TableView/helpers/helpers";
const {secondCustTabMasterKey} = requestedMenuAll;
const {updateKey, deleteKey} = requestedFeaturesAll;

export const columns = ({onRowEdit, onRowDelete, deletePrimaryCustomerMasterStatus,
  isUpdateDeleteAccess}) => ([...[
  {
    header: 's-no',
    accessorKey: 'id',
    cell: (value) => {
      const fdPrimeCusId = value.row.index + 1;
     return fdPrimeCusId;
    }
  },
  {
    header: 'customer-code',
    accessorKey: 'secCusCode'
  },
  {
    header: 'customer-name',
    accessorKey: 'secCusName'
  },
  {
    header: 'customer-category',
    accessorKey: 'secCusCategory'
  },
  {
    header: 'customer-gst',
    hideColumn: true,
    accessorKey: 'secCusGstNo'
  },
  {
    header: 'contact-person',
    accessorKey: 'contactPerson'
  },
  {
    header: 'contact-number',
    accessorKey: 'contactNo'
  },
  {
    header: 'sub-zone',
    accessorKey: 'subZoneName'
  },
  {
    header: 'secondary-customer',
    accessorKey: 'secondaryCustomerName'
  },
  {
    header: 'city',
    accessorKey: 'cityName'
  },
  {
    header: 'latitude',
    hideColumn: true,
    accessorKey: 'latitude'
  },
  {
    header: 'longitude',
    hideColumn: true,
    accessorKey: 'longitude'
  },
  {
    header: 'image',
    hideColumn: true,
    accessorKey: 'image'
  },
  {
    header: 'address',
    hideColumn: true,
    accessorKey: 'address'
  },
  {
    header: 'landmark',
    accessorKey: 'landmark'
  },
  {
    header: 'pincode',
    hideColumn: true,
    accessorKey: 'pincode'
  },
  {
    header: 'created-at',
    hideColumn: true,
    accessorKey: 'createdAt',
    cell: ({ getValue }) => dateString(getValue()) 
  },
  {
    header: 'created-by',
    hideColumn: true,
    accessorKey: 'createdBy'
  },
  {
    header: 'updated-at',
    hideColumn: true,
    accessorKey: 'updatedAt',
    cell: ({ getValue }) => dateString(getValue()) 
  },
  {
    header: 'updated-by',
    hideColumn: true,
    accessorKey: 'updatedBy'
  },
  {
    header: 'status',
    accessorKey: 'activeStatus',
    cell: (cell) => {
      switch (cell.getValue()) {
        case '0':
          return <Chip color="error" label="In-Active" size="small" variant="light" />;
        case '1':
          return <Chip color="success" label="Active" size="small" variant="light" />;

      }
    }
  }
], ...(isUpdateDeleteAccess ? [
  {
    header: 'action',
    id: "edit",
    cell: (props) => (
      <Grid container className="table-icon-actions-2">
        <VerifyAccess requestedMenu={secondCustTabMasterKey} requestedFeature={updateKey}>
        <Grid item>
          <EditAction
            {...props}
            onRowEdit={onRowEdit}
          />
        </Grid>
        </VerifyAccess>
        <VerifyAccess requestedMenu={secondCustTabMasterKey} requestedFeature={deleteKey}>
        <Grid item>
          <DeleteAction
            {...props}
            onRowDelete={onRowDelete} 
            deletePrimaryCustomerMasterStatus={deletePrimaryCustomerMasterStatus}
          />
        </Grid>
        </VerifyAccess>
      </Grid>
    ),
    meta: {
      className: 'cell-center'
    }
  }

]: [])]);

export const initialValues = {
  stateId: null,
  districtId: null,
  cityId: null,
}

export const reportFileName = "Secondary Customer Master";
