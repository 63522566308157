import { lazy } from 'react';

// project import
import AuthLayout from '@/from-theme/layout/Auth';
import Loadable from '@/from-theme/components/Loadable';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/common/auth/login')));
const AuthRegister = Loadable(lazy(() => import('pages/common/auth/register')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/common/auth/forgot-password')));
const AuthCheckMail = Loadable(lazy(() => import('pages/common/auth/check-mail')));
const AuthResetPassword = Loadable(lazy(() => import('pages/common/auth/reset-password')));
const AuthCodeVerification = Loadable(lazy(() => import('pages/common/auth/code-verification')));
const OtpValidation = Loadable(lazy(() => import('pages/common/auth/otp-validation')));
const NoAccess = Loadable(lazy(() => import('pages/common/no-access/no-access')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: <AuthLayout />,
      children: [
        {
          path: '/',
          element: <AuthLogin />
        },
        {
          path: 'login',
          element: <AuthLogin />
        },
        {
          path: 'register',
          element: <AuthRegister />
        },
        {
          path: 'forgot-password',
          element: <AuthForgotPassword />
        },
        {
          path: 'check-mail',
          element: <AuthCheckMail />
        },
        {
          path: 'reset-password',
          element: <AuthResetPassword />
        },
        {
          path: 'code-verification',
          element: <AuthCodeVerification />
        },
        {
          path: 'otp-validation',
          element: <OtpValidation />
        },        
        {
          path: 'no-access',
          element: <NoAccess />
        }
       
      ]
    }
  ]
};

export default LoginRoutes;
