import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import {  getStatus } from "@/store/slices/helpers/helpers";
import { clearData, fetchData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiUrls } from "@/store/api/constants";
import navs from "@/from-theme/routes/constants/nav";
import { useNavigate } from "react-router";
import { getEncoded } from "@/common/helpers/helpers";

export default function useOnActions() {
  const [isAlertDialog, setIsAlertDialog] = useState(false);
  const [currentRowId, setCurrentRowId] = useState(null);

  const {formatMessage} = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const deleteShiftMasterStatus = getStatus(state, apiKeys.deleteShiftMaster, '');
  
  const onCloseAlert = useCallback(() => {
    setIsAlertDialog((prevState) => !prevState);
    setCurrentRowId(null);
  },[]);

  const onAgreeAlert = useCallback(() => {
    dispatch(fetchData({
      url: apiUrls.deleteShiftMaster(currentRowId),
      method: apiMethods.DELETE,
      key: apiKeys.deleteShiftMaster,
      successMsg: formatMessage({id: "shift-master-has-been-deleted-successfully"}),
      failureMsg: formatMessage({id: "failed-to-delete-shift-master"}),
    }))
    onCloseAlert();
  }, [dispatch, formatMessage, onCloseAlert, currentRowId]);

  
  const onRowDelete = useCallback((id) => {
    setCurrentRowId(id);
    setIsAlertDialog(true);
  }, []);

  const onRowEdit = useCallback((id) => {
    navigate(navs.editShiftMaster(getEncoded(id)))
  }, [navigate])


  useEffect(() => ()=> {
    dispatch(clearData({key: apiKeys.deleteShiftMaster}));
  }, [dispatch]);

    return useMemo(() => ({
        onRowEdit,
        onRowDelete,
        deleteShiftMasterStatus,
        isAlertDialog,
        onCloseAlert,
        onAgreeAlert
    }),[
        onRowEdit,
        onRowDelete,
        deleteShiftMasterStatus,
        isAlertDialog,
        onCloseAlert,
        onAgreeAlert
    ])
}
