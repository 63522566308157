
export const initialValues = {
  divisionId: undefined,
  vehicleType: undefined,
  ratePerDay: "",
  limitPerDayKm: "",
  limitPerMonthKm: "",
  elValidFrom: new Date().toISOString().split('T')[0], // Today's date in 'YYYY-MM-DD' format
  elValidTo: '2099-12-31', // Set validTo to 31-12-2099 in 'YYYY-MM-DD' format
  activeStatus: 1,


}