import React from 'react';
import { Button, DialogActions, DialogContent, Grid } from '@mui/material';
import TextInput from '@/common/components/text-input/text-input';
import Dropdown from '@/common/components/dropdown/dropdown';
import SwitchInput from '@/common/components/switch-input/switch-input';
import AnimateButton from '@/from-theme/components/@extended/AnimateButton';
import { FormattedMessage } from 'react-intl';
import useCreateEditExpenseLimitMaster from './hooks/useCreateEditExpenseLimitMaster';
import PropTypes from 'prop-types';
import { apiStatuses } from '@/store/api/constants';
import ErrorInPage from '@/common/components/error-in-page/error-in-page';
import Spinner from '@/common/components/spinner/spinner';
import useGetDropdownListOptions from '@/common/hooks/useGetDropdownListMaster';
import useGetDivisionOptions from '@/common/hooks/useGetDivisionMaster';
import DateInput from '@/common/components/date-input/date-input';

function CreateEditExpenseLimitMaster({ closeModal, activeEditId }) {
  const { getDivisionOptions, getDivisionLoading } = useGetDivisionOptions();

  const { transportModeOptions, getDropdownListLoading } = useGetDropdownListOptions();
  const { values, formik, getEditExpenseLimitMasterStatus,  saveExpenseLimitMasterStatus,
    updateExpenseLimitMasterStatus, getEditExpenseLimitMasterErrMsg } =
    useCreateEditExpenseLimitMaster(activeEditId);
      const isPending = [ saveExpenseLimitMasterStatus,
        updateExpenseLimitMasterStatus,].includes(apiStatuses.pending);
    
  const { divisionId, vehicleType, ratePerDay, limitPerDayKm, limitPerMonthKm, elValidFrom,
    elValidTo, activeStatus } = values;


  return (
    <>
      {getEditExpenseLimitMasterStatus === apiStatuses.pending && activeEditId && <Spinner />}

      {getEditExpenseLimitMasterStatus === apiStatuses.rejected && activeEditId && (
        <ErrorInPage message={getEditExpenseLimitMasterErrMsg} />
      )}

      {(getEditExpenseLimitMasterStatus === apiStatuses.succeeded || !activeEditId) && (
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <Grid container spacing={1.25} >
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <Dropdown
                  options={getDivisionOptions}
                  loading={getDivisionLoading}
                  placeholder="division-name"
                  inputLabel="division-name"
                  value={divisionId}
                  formik={formik}
                  name="divisionId"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <Dropdown
                  options={transportModeOptions}
                  loading={getDropdownListLoading}
                  placeholder="vehicle-type"
                  inputLabel="vehicle-type"
                  value={vehicleType}
                  formik={formik}
                  name="vehicleType"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="rate-per-day"
                  size="small"
                  fullWidth
                  value={ratePerDay}
                  inputLabel="rate-per-day"
                  formik={formik}
                  name="ratePerDay"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="limit-per-day-km"
                  size="small"
                  fullWidth
                  value={limitPerDayKm}
                  inputLabel="limit-per-day-km"
                  formik={formik}
                  name="limitPerDayKm"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="limit-per-month-km"
                  size="small"
                  fullWidth
                  value={limitPerMonthKm}
                  inputLabel="limit-per-month-km"
                  formik={formik}
                  name="limitPerMonthKm"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <DateInput inputLabel="valid-from" placeholder="valid-from" value={elValidFrom} 
                formik={formik} name="elValidFrom" />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <DateInput inputLabel="valid-to" placeholder="valid-to" value={elValidTo}
                 formik={formik} name="elValidTo" />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <SwitchInput
                  inputLabel="activeStatus"
                  leftLabel="inactive"
                  rightLabel="active"
                  value={activeStatus}
                  formik={formik}
                  name="activeStatus"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={closeModal} disabled={isPending} 
            variant="outlined" sx={{ mr: 1 }}>
              <FormattedMessage id={'cancel'} />
            </Button>
            <AnimateButton>
              <Button type="submit" variant="contained" disabled={isPending} sx={{ mr: 1 }}>
                <FormattedMessage id={'save'} />
              </Button>
            </AnimateButton>
          </DialogActions>
        </form>
      )}
    </>
  );
}

CreateEditExpenseLimitMaster.propTypes = {
  closeModal: PropTypes.func.isRequired,
  activeEditId: PropTypes.bool
};

CreateEditExpenseLimitMaster.defaultProps = {
  activeEditId: 0
};

export default CreateEditExpenseLimitMaster;
