import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from 'react-intl';
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getData, getStatus, getErrorMessage } from "@/store/slices/helpers/helpers";
import { clearData, fetchData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiUrls } from "@/store/api/constants";

export default function useTarget() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const state = useSelector((store) => store);
  const [selectedFile, setSelectedFile] = useState(null);

  const shiftMasterData = getData(state, apiKeys.shiftMaster, []);
  const shiftMasterStatus = getStatus(state, apiKeys.shiftMaster, '');
  const targetFileUploadStatus = getStatus(state, apiKeys.targetFileUpload, '');
  const shiftMasterErrMsg = getErrorMessage(state, apiKeys.shiftMaster, '');

  const validationSchema = yup.object({
    selectedMonthYear: yup.string().required(formatMessage({ id: "this-field-is-required" })),
  });

  const formik = useFormik({
    initialValues: {
      selectedMonthYear: "",
    },
    validationSchema,
  });

  console.log(targetFileUploadStatus,'5546');

  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;

  const { values } = formik;
  const { selectedMonthYear } = values;

  const onSearch = useCallback(() => {
    dispatch(fetchData({
      url: apiUrls.shiftMaster,
      method: apiMethods.GET,
      key: apiKeys.shiftMaster,
      failureMsg: formatMessage({ id: "failed-to-get-shift-master" }),
    }));
  }, [dispatch, formatMessage]);

  const handleDownloadCSV = useCallback(() => {
    const fileUrl = '/assets/files/target-sample-upload.xlsx';
    const a = document.createElement('a');
    a.href = fileUrl;
    a.download = 'target-sample-upload.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, []);

  const handleFileChange = useCallback((event) => {
    setSelectedFile(event.target.files[0]);
  }, []);

  const handleUploadCSV = useCallback(() => {
    if (!selectedFile || !selectedMonthYear) {
      console.log('No file selected or selectedMonthYear is empty');
      return;
    }
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("selectedMonthYear", selectedMonthYear);
  
    dispatch(fetchData({
      url: apiUrls.targetFileUpload,
      method: apiMethods.POST,
      key: apiKeys.targetFileUpload,
      body: formData, // Use FormData here
      headers: {
        "Content-Type": "multipart/form-data", // Important for file uploads
      },
      failureMsg: formatMessage({ id: "failed-to-upload-target-file" }),
    }));
  
    console.log('Uploading file:', selectedFile);
  }, [dispatch, formatMessage, selectedFile, selectedMonthYear]);


  useEffect(() => {
    return () => {
      dispatch(clearData({ key: apiKeys.shiftMaster }));
    };
  }, [dispatch]);

  return useMemo(() => ({
    formik,
    values: formik.values,
    selectedMonthYear,
    selectedFile,
    onSearch,
    shiftMasterData,
    shiftMasterStatus,
    shiftMasterErrMsg,
    handleDownloadCSV,
    handleFileChange,
    handleUploadCSV
  }), [
    formik,
    selectedMonthYear,
    selectedFile,
    onSearch,
    shiftMasterData,
    shiftMasterStatus,
    shiftMasterErrMsg,
    handleDownloadCSV,
    handleFileChange,
    handleUploadCSV
  ]);
}
