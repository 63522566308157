import React from "react";
import {
  Button, DialogActions, DialogContent, Grid
} from '@mui/material';
import TextInput from "@/common/components/text-input/text-input";
import Dropdown from "@/common/components/dropdown/dropdown";
import SwitchInput from "@/common/components/switch-input/switch-input";
import AnimateButton from "@/from-theme/components/@extended/AnimateButton";
import { FormattedMessage } from 'react-intl';
import { apiStatuses } from "@/store/api/constants";
import useCreateEditZoneMaster from "./hooks/useCreateEditZoneMaster";
import useGetDivisionOptions from "@/common/hooks/useGetDivisionMaster";
import useGetStateOptions from "@/common/hooks/useGetStateMaster";
import Spinner from "@/common/components/spinner/spinner";
import ErrorInPage from "@/common/components/error-in-page/error-in-page";
import PropTypes from "prop-types";


function CreateEditZoneMaster({
  closeModal, activeEditId
}) {

  const { values, formik,getEditZoneMasterStatus, saveZoneMasterStatus, updateZoneMasterStatus,
    getEditZoneMasterErrMsg } = useCreateEditZoneMaster(activeEditId);

  const isPending = [saveZoneMasterStatus, updateZoneMasterStatus].includes(apiStatuses.pending);

  const { getDivisionOptions, getDivisionLoading } = useGetDivisionOptions();
  const { getStateOptions, getStateLoading } = useGetStateOptions();
  const {
    divisionId, zoneName, activeStatus, stateId
  } = values;

  return (
    <>
    {
      getEditZoneMasterStatus === apiStatuses.pending && activeEditId
      && <Spinner />
    }
    {getEditZoneMasterStatus === apiStatuses.rejected && activeEditId && (
      <ErrorInPage message={getEditZoneMasterErrMsg} />
    )}
    {(getEditZoneMasterStatus === apiStatuses.succeeded || !activeEditId) &&
    <form onSubmit={formik.handleSubmit}>
      <DialogContent dividers sx={{ overflow: 'visible' }} >
        <Grid container spacing={1.25}>
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <TextInput
              placeholder="zone" size="small" fullWidth
              value={zoneName}
              inputLabel="zone"
              formik={formik}
              name="zoneName"
              
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <Dropdown
              options={getDivisionOptions}
              loading={getDivisionLoading}
              placeholder="division-name"
              inputLabel="division-name"
              value={divisionId}
              formik={formik}
              disablePortal
              name="divisionId"
          
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <Dropdown
              options={getStateOptions}
              loading={getStateLoading}
              placeholder="state-name"
              inputLabel="state-name"
              value={stateId}
              disablePortal
              formik={formik}
              name="stateId"
              isCheckBox
              multiple
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <SwitchInput
              inputLabel="activeStatus"
              leftLabel="inactive"
              rightLabel="active"
              value={activeStatus}
              formik={formik}
              name="activeStatus"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={closeModal} variant="outlined" disabled={isPending}
         sx={{ mr: 1 }}>
          <FormattedMessage id={"cancel"} />
        </Button>
        <AnimateButton>
          <Button type="submit" variant="contained" disabled={isPending} sx={{ mr: 1 }}>
            <FormattedMessage id={"save"} />
          </Button>
        </AnimateButton>
      </DialogActions>
    </form>
    }
    </>
  )
}

CreateEditZoneMaster.propTypes = {
  closeModal: PropTypes.func.isRequired,
  activeEditId: PropTypes.bool,
};

CreateEditZoneMaster.defaultProps = {
  activeEditId: 0,
};

export default CreateEditZoneMaster;