import { useState } from 'react';
import { Stack, Tooltip, Modal, Typography, Button, TextField } from "@mui/material";
import PropTypes from 'prop-types';

function SeeMore({ content, value }) {
    const [open, setOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // Split the content by commas and trim any extra spaces
    const contentItems = content.split(',').map(item => item.trim());

    // Filter content items based on search query
    const filteredItems = contentItems.filter(item => 
        item.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Tooltip >
                <Button
                    sx={{ color: 'blue', marginLeft: '5px' }}
                    onClick={handleOpen}
                >
                    See More...
                </Button>
            </Tooltip>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Stack
                    sx={{
                        position: 'absolute',
                        width: 900,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        borderRadius: '25px',
                        boxShadow: 24,
                        p: 4,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        maxHeight: '80vh', 
                        overflowY: 'auto', 
                    }}
                >
                    <Stack direction="row" justifyContent="space-between" alignItems="center" 
                    sx={{ mb: 2 }}>
                        <Typography id="modal-modal-title" variant="h4" component="h2">
                            See More {value}
                        </Typography>
                        <Button onClick={handleClose} sx={{ color: 'red' }}>
                            Close
                        </Button>
                    </Stack>
                    <TextField
                        variant="outlined"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        sx={{ mb: 2 }} // Optional: Add some margin at the bottom
                    />
                    <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
                        {filteredItems.length > 0 ? (
                            filteredItems.map((item, index) => (
                                <Typography key={index} variant="body1">{item}</Typography>
                            ))
                        ) : (
                            <Typography variant="body1">No items found</Typography>
                        )}
                    </Stack>
                </Stack>
            </Modal>
        </Stack>
    );
}

SeeMore.propTypes = {
    content: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

export default SeeMore;
