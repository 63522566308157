import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getStatus, getErrorMessage } from "@/store/slices/helpers/helpers";
import { clearData, fetchData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";

export default function useExpenseLimitMaster() {
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [activeEditId, setActiveEditId] = useState(0);
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const expenselimitMasterData = getData(state, apiKeys.expenselimitMaster, []);
  const saveExpenseLimitMasterStatus = getStatus(state, apiKeys.saveExpenseLimitMaster, '');
  const updateExpenseLimitMasterStatus = getStatus(state, apiKeys.updateExpenseLimitMaster, '');
  const expenselimitMasterStatus = getStatus(state, apiKeys.expenselimitMaster, '');
  const expenselimitMasterErrMsg = getErrorMessage(state, apiKeys.expenselimitMaster, '');
  const deleteExpenseLimitMasterStatus = getStatus(state, apiKeys.deleteExpenseLimitMaster, '');


  const getExpenseLimitMaster = useCallback(() => {
    dispatch(fetchData({
      url: apiUrls.expenselimitMaster,
      method: apiMethods.GET,
      key: apiKeys.expenselimitMaster,
      failureMsg: formatMessage({ id: "failed-to-get-expenselimit-master" }),
    }))
  }, [dispatch, formatMessage])

  useEffect(() => {
    getExpenseLimitMaster()
  }, [dispatch, getExpenseLimitMaster]);

  useEffect(() => {
    if (deleteExpenseLimitMasterStatus === apiStatuses.succeeded) {
      getExpenseLimitMaster();
    }
  }, [deleteExpenseLimitMasterStatus, getExpenseLimitMaster])

  useEffect(() => () => {
    dispatch(clearData({ key: apiKeys.expenselimitMaster }));
  }, [dispatch]);

  const onRowEdit = useCallback((id) => {
    setIsCreateEditModalOpen(true);
    setActiveEditId(id);
  }, [])
  const closeModal = useCallback(() => {
    setIsCreateEditModalOpen(false);
    setActiveEditId(0);
  }, []);

  
  useEffect(() => {
    if (saveExpenseLimitMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getExpenseLimitMaster();
    } 
  }, [saveExpenseLimitMasterStatus, closeModal, getExpenseLimitMaster]);

  useEffect(() => {
    if (updateExpenseLimitMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getExpenseLimitMaster();
    } 
  }, [updateExpenseLimitMasterStatus, closeModal, getExpenseLimitMaster]);

  return useMemo(() => ({
    expenselimitMasterData,
    expenselimitMasterStatus,
    expenselimitMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  }), [
    expenselimitMasterData,
    expenselimitMasterStatus,
    expenselimitMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  ])
}
