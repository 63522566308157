import { BookOutlined, UserOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import Countries from '../components/country/country';
import States from '../components/states/states';
import Districts from '../components/districts/districts';
import Cities from '../components/cities/cities';
// import Areas from '../components/areas/areas';

import { requestedFeaturesAll, requestedMenuAll } 
from '../../../../from-theme/routes/constants/menuAccess';
import VerifyAccess from '@/common/components/verify-access/verify-access';

const { countryTabMasterKey, stateTabMasterKey, districtTabMasterKey, 
    cityTabMasterKey,
    //  areaTabMasterKey
     } = requestedMenuAll;
const { readKey } = requestedFeaturesAll;

// tabName is locale id
export const locationMasterTabs = [
  {
    id: 'countries',
    tabName: 'countries',
    icon: <UserOutlined />,
    tabContent: (
      <VerifyAccess requestedMenu={countryTabMasterKey} requestedFeature={readKey}>
        <Countries />
      </VerifyAccess>
    )
  },
  {
    id: 'states',
    tabName: 'states',
    icon: <BookOutlined />,
    tabContent: (
      <VerifyAccess requestedMenu={stateTabMasterKey} requestedFeature={readKey}>
        <States />
      </VerifyAccess>
    )
  },
  {
    id: 'districts',
    tabName: 'districts',
    icon: <UsergroupAddOutlined />,
    tabContent: (
      <VerifyAccess requestedMenu={districtTabMasterKey} requestedFeature={readKey}>
        <Districts />
      </VerifyAccess>
    )
  },
  {
    id: 'cities',
    tabName: 'cities',
    icon: <UsergroupAddOutlined />,
    tabContent: (
      <VerifyAccess requestedMenu={cityTabMasterKey} requestedFeature={readKey}>
        <Cities />
      </VerifyAccess>
    )
  },
  // {
  //   id: 'areas',
  //   tabName: 'areas',
  //   icon: <UsergroupAddOutlined />,
  //   tabContent: (
  //     <VerifyAccess requestedMenu={areaTabMasterKey} requestedFeature={readKey}>
  //       <Areas />
  //     </VerifyAccess>
  //   )
  // }
];
