import React from "react";
import TableView from "@/common/components/TableView/TableView";
import { Grid, Button } from "@mui/material";
import { PlusOutlined } from "@ant-design/icons";
import { columns, reportFileName } from "./constants/constants";
import useExpenseLimitMaster from "./hooks/useExpenseLimitMaster";
import CreateEditEnergyTypeMaster from "./create-expenselimit-master/create-expenselimit-master";
import ModalDialog from "@/common/components/modal-dialog/modal-dialog";
import { FormattedMessage } from "react-intl";
import useOnActions from "./hooks/useOnActions";
import AlertDialog from "@/common/components/alert-dialog/alert-dialog";
import VerifyAccess from "@/common/components/verify-access/verify-access";
import { requestedMenuAll, requestedFeaturesAll } from "@/from-theme/routes/constants/menuAccess";
import { isUserHasAccess } from "@/from-theme/utils/route-guard/helpers/helpers";
import useAuth from "@/from-theme/hooks/useAuth";

const {expenseLimitTabMasterKey} = requestedMenuAll;
const {createKey, updateKey, deleteKey} = requestedFeaturesAll;

export default function ExpenseLimit() {
    const { expenselimitMasterData, isCreateEditModalOpen, setIsCreateEditModalOpen, onRowEdit,
        activeEditId, closeModal, expenselimitMasterStatus, expenselimitMasterErrMsg }
         = useExpenseLimitMaster();
const { userData } = useAuth();
const entitlement = userData?.entitlement || {};

const isUpdateDeleteAccess = isUserHasAccess(expenseLimitTabMasterKey, updateKey, entitlement)
|| isUserHasAccess(expenseLimitTabMasterKey, deleteKey, entitlement);        

    const {
        onRowDelete,
        deleteEnergyTypeMasterStatus,
        isAlertDialog,
        onCloseAlert,
        onAgreeAlert
    } = useOnActions();
    const actionButton = (
        <VerifyAccess requestedMenu={expenseLimitTabMasterKey} requestedFeature={createKey}>
        <Button
            variant="contained"
            startIcon={<PlusOutlined />}
            onClick={() => setIsCreateEditModalOpen(true)}
        ><FormattedMessage id="add-expense-limit" />
        </Button>
        </VerifyAccess>
    );

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TableView
                    data={expenselimitMasterData}
                    columns={columns({ onRowEdit, onRowDelete, deleteEnergyTypeMasterStatus,
                        isUpdateDeleteAccess })}
                    actionButton={actionButton}
                    reportFileName={reportFileName}
                    apiStatus={expenselimitMasterStatus}
                    apiErrorMsg={expenselimitMasterErrMsg}
                    stringInExport={["activeStatus"]}
                />
                {isCreateEditModalOpen && (
                    <ModalDialog
                        closeModal={() => closeModal()}
                        modalTitle={!activeEditId ? "create-expense-limit-master" :
                         "edit-expense-limit-master"}
                    >
                        <CreateEditEnergyTypeMaster closeModal={() => closeModal()}
                            activeEditId={activeEditId} />
                    </ModalDialog>
                )}
                {isAlertDialog && (
                    <AlertDialog onCloseAlert={onCloseAlert} onAgreeAlert={onAgreeAlert} />
                )}
            </Grid>
        </Grid>
    );
}
