import { Alert, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import useCreateEditPrimaryCustomerMaster from './hooks/useCreateEditPrimaryCustomerMaster';
import Dropdown from '@/common/components/dropdown/dropdown';
import { FormattedMessage } from 'react-intl';
import TextInput from '@/common/components/text-input/text-input';
import SwitchInput from '@/common/components/switch-input/switch-input';
import AnimateButton from '@/from-theme/components/@extended/AnimateButton';
import { apiStatuses } from '@/store/api/constants';
import Spinner from '@/common/components/spinner/spinner';
import ErrorInPage from '@/common/components/error-in-page/error-in-page';
import MainCard from '@/from-theme/components/MainCard';
import useGetDropdownListOptions from '@/common/hooks/useGetDropdownListMaster';
import useGetCityMasterByDistrict from '@/common/hooks/useGetCityByDistrict';
import SingleFileUpload from '@/from-theme/components/third-party/dropzone/SingleFile';
import TextInputIcon from '@/common/components/text-inputIcon/text-input-icon';
import useGetUserByDivision from '@/common/hooks/useGetUserByDivision';
import useGetZoneOptions from '@/common/hooks/useGetZoneMaster';
import useGetSubZoneMaster from '@/common/hooks/useGetSubZoneMaster';
import useGetDistrictMasterBySubzone from '@/common/hooks/useGetDistrictBySubzone';
import { WarningFilled } from '@ant-design/icons';


function CreateEditPrimaryCustomerMaster({ activeEditId }) {
  const { primeCustCategory, priceGroupOptions, getDropdownListLoading } = 
  useGetDropdownListOptions();
  const { getSubZoneListOptions, getSubZoneListLoading } = useGetSubZoneMaster();
  const { getZoneOptions, getZoneLoading } = useGetZoneOptions();

  const {
    getDistrictsBySubzoneOptions: districtsData,
    getDistrictsBySubzone: getDistrict,
    getDistrictsBySubzoneLoading: districtsLoading
  } = useGetDistrictMasterBySubzone('stateId');
  const { getUserListOptions, getUserListLoading } = useGetUserByDivision();

  const {
    getCitysByDistrictOptions: citiesData,
    getCitysByDistrict: getCity,
    getCitysByDistrictLoading: citiesLoading
  } = useGetCityMasterByDistrict('district');

  const {
    values,
    formik,
    alertVisible,
    getEditPrimaryCustomerMasterStatus,
    handleClick,
    handleSave,
    onChangeZone,
    onChangeSubzone,
    onChangeDistrict,
    savePrimaryCustomerMasterStatus,
    getEditPrimaryCustomerMasterErrMsg,
    getPrimeCustDataFromSapStatus
  } = useCreateEditPrimaryCustomerMaster({
    activeEditId,
    getDistrict,
    getCity,
  });

    const isPending = [savePrimaryCustomerMasterStatus].includes(apiStatuses.pending);
  

  const { setFieldValue, errors } = formik;

  const {
    primeCusSapCode,
    primeCusName,
    sapPrimeCusCategory,
    primeCusGstNo,
    address,
    file,
    landmark,
    districtId,
    partyCode,
    salesSupportRep,
    teamLeaderName,
    zoneHeadName,
    salesHeadName,
    salesOrg,
    priceGroup,
    primeCusCategory,
    pincode,
    incoterms,
    sapCountry,
    sapZoneName,
    sapSubzoneName,
    sapDistrictName,
    sapPriceGroupName,
    sapCityName,
    sapCompany,
    regionCode,
    subzoneId,
    activeStatus,
    sapStateName,
    cityId,
    zoneId,
    contactPerson,
    sapTeamLeaderName,
    sapSalesSupportRep,
    sapZoneHeadName,
    sapSalesHeadName,
    contactWhatsappNo,
    primeCusEmail,
    contactNo,
    latitude,
    longitude,
    uploadFile,
    onRemoveCallBack
  } = values;

  let shipToPartCode = '';
  if (partyCode && typeof partyCode === 'object') {
    const shipToPartyCodeValues = partyCode.map(item => item.COMMONINFO);
    shipToPartCode = shipToPartyCodeValues.join(', ');
  }

  return (
    <>
      {(getPrimeCustDataFromSapStatus === apiStatuses.pending ||
        (getEditPrimaryCustomerMasterStatus === apiStatuses.pending && 
        activeEditId)) && <Spinner />}
      {getEditPrimaryCustomerMasterStatus === apiStatuses.rejected && activeEditId && (
        <ErrorInPage message={getEditPrimaryCustomerMasterErrMsg} />
      )}
      {(getEditPrimaryCustomerMasterStatus === apiStatuses.succeeded || !activeEditId) && (
        <form onSubmit={formik.handleSubmit}>
          <MainCard>
          {alertVisible && Object.entries(formik.errors).map(([field, error]) => (
            <Alert
              key={field} 
              severity="error"
              variant="outlined"
              icon={<WarningFilled />} 
              sx={{ mb: 2 }} 
            >
              <Typography variant="body1">{`${field}: ${error}`}</Typography>
            </Alert>
          ))}

            <Grid container spacing={2} >
              <Grid item xs={12} sm={12} md={4} lg={3}>
                <TextInputIcon
                  placeholder="sap-code"
                  size="small"
                  fullWidth
                  value={primeCusSapCode || ''}
                  inputLabel="sap-code"
                  formik={formik}
                  name="primeCusSapCode"
                  onSearchIconClick={!activeEditId ? handleClick : undefined}
                  disabled={getPrimeCustDataFromSapStatus === 'pending'}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && !activeEditId) {
                      handleClick();
                    }
                  }}
                />
              </Grid>
              {primeCusName && (
                <>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="primary-customer-name"
                      size="small"
                      fullWidth
                      value={primeCusName || ''}
                      inputLabel="primary-customer-name"
                      formik={formik}
                      name="primeCusName"
                    />
                  </Grid>
                 
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="sap-company-code"
                      size="small"
                      fullWidth
                      value={sapCompany || ''}
                      inputLabel="sap-company-code"
                      formik={formik}
                      name="sapCompany"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="region-code"
                      size="small"
                      fullWidth
                      value={regionCode || ''}
                      inputLabel="region-code"
                      formik={formik}
                      name="regionCode"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="sales-org"
                      size="small"
                      fullWidth
                      value={salesOrg || ''}
                      inputLabel="sales-org"
                      formik={formik}
                      name="salesOrg"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="inco-terms"
                      size="small"
                      fullWidth
                      value={incoterms || ''}
                      inputLabel="inco-terms"
                      formik={formik}
                      name="incoterms"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="customer-gst"
                      size="small"
                      fullWidth
                      value={primeCusGstNo || ''}
                      inputLabel="customer-gst"
                      formik={formik}
                      name="primeCusGstNo"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="sap-customer-group"
                      size="small"
                      fullWidth
                      value={sapPrimeCusCategory || ''}
                      inputLabel="sap-customer-group"
                      formik={formik}
                      name="sapPrimeCusCategory"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Dropdown
                      options={primeCustCategory}
                      loading={getDropdownListLoading}
                      placeholder="customer-category"
                      inputLabel="customer-category"
                      value={primeCusCategory || ''}
                      formik={formik}
                      name="primeCusCategory"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="dist-party-code"
                      size="small"
                      fullWidth
                      value={shipToPartCode || ''}
                      inputLabel="dist-party-code"
                      formik={formik}
                      name="shipToPartCode"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="contact-person"
                      size="small"
                      fullWidth
                      value={contactPerson || ''}
                      inputLabel="contact-person"
                      formik={formik}
                      name="contactPerson"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="contact-number"
                      size="small"
                      fullWidth
                      value={contactNo || ''}
                      inputLabel="contact-number"
                      formik={formik}
                      name="contactNo"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="primary-customer-email"
                      size="small"
                      fullWidth
                      value={primeCusEmail || ''}
                      inputLabel="primary-customer-email"
                      formik={formik}
                      name="primeCusEmail"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="whatsapp-number"
                      size="small"
                      fullWidth
                      value={contactWhatsappNo || ''}
                      inputLabel="whatsapp-number"
                      formik={formik}
                      name="contactWhatsappNo"
                    />
                  </Grid>
                 
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="sap-price-group"
                      size="small"
                      fullWidth
                      value={sapPriceGroupName || ''}
                      inputLabel="sap-price-group"
                      formik={formik}
                      name="sapPriceGroupName"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Dropdown
                      options={priceGroupOptions}
                      loading={getDropdownListLoading}
                      placeholder="price-group"
                      inputLabel="price-group"
                      value={priceGroup || ''}
                      formik={formik}
                      name="priceGroup"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="sap-sales-head"
                      size="small"
                      fullWidth
                      value={sapSalesHeadName || ''}
                      inputLabel="sap-sales-head"
                      formik={formik}
                      name="sapSalesHeadName"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Dropdown
                      options={getUserListOptions}
                      loading={getUserListLoading}
                      placeholder="sales-head"
                      inputLabel="sales-head"
                      value={salesHeadName || ''}
                      formik={formik}
                      name="salesHeadName"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="sap-zone-head"
                      size="small"
                      fullWidth
                      value={sapZoneHeadName || ''}
                      inputLabel="sap-zone-head"
                      formik={formik}
                      name="sapZoneHeadName"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Dropdown
                      options={getUserListOptions}
                      loading={getUserListLoading}
                      placeholder="zone-head"
                      inputLabel="zone-head"
                      value={zoneHeadName || ''}
                      formik={formik}
                      name="zoneHeadName"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="sap-team-leader"
                      size="small"
                      fullWidth
                      value={sapTeamLeaderName || ''}
                      inputLabel="sap-team-leader"
                      formik={formik}
                      name="sapTeamLeaderName"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Dropdown
                      options={getUserListOptions}
                      loading={getUserListLoading}
                      placeholder="team-leader"
                      inputLabel="team-leader"
                      value={teamLeaderName || ''}
                      formik={formik}
                      name="teamLeaderName"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="sap-sales-support-rep"
                      size="small"
                      fullWidth
                      value={sapSalesSupportRep || ''}
                      inputLabel="sap-sales-support-rep"
                      formik={formik}
                      name="sapSalesSupportRep"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Dropdown
                      options={getUserListOptions}
                      loading={getUserListLoading}
                      placeholder="sales-support-rep"
                      inputLabel="sales-support-rep"
                      value={salesSupportRep || ''}
                      formik={formik}
                      name="salesSupportRep"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="sap-country-name"
                      size="small"
                      fullWidth
                      value={sapCountry || ''}
                      inputLabel="sap-country-name"
                      formik={formik}
                      name="sapCountry"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="sap-zone-name"
                      size="small"
                      fullWidth
                      value={sapZoneName || ''}
                      inputLabel="sap-zone-name"
                      formik={formik}
                      name="sapZoneName"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Dropdown
                      options={getZoneOptions}
                      loading={getZoneLoading}
                      placeholder="zone-name"
                      inputLabel="zone-name"
                      value={zoneId || ''}
                      formik={formik}
                      name="zoneId"
                      onChange={onChangeZone}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="sap-sub-zone"
                      size="small"
                      fullWidth
                      value={sapSubzoneName || ''}
                      inputLabel="sap-sub-zone"
                      formik={formik}
                      name="sapSubzoneName"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Dropdown
                      options={getSubZoneListOptions}
                      loading={getSubZoneListLoading}
                      placeholder="sub-zone"
                      inputLabel="sub-zone"
                      value={subzoneId || ''}
                      formik={formik}
                      name="subzoneId"
                      onChange={onChangeSubzone}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="sap-state"
                      size="small"
                      fullWidth
                      value={sapStateName || ''}
                      inputLabel="sap-state"
                      formik={formik}
                      name="sapStateName"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="sap-district"
                      size="small"
                      fullWidth
                      value={sapDistrictName || ''}
                      inputLabel="sap-district"
                      formik={formik}
                      name="sapDistrictName"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Dropdown
                      options={districtsData}
                      loading={districtsLoading}
                      placeholder="district"
                      inputLabel="district"
                      value={districtId || ''}
                      formik={formik}
                      name="districtId"
                      onChange={onChangeDistrict}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="sap-city"
                      size="small"
                      fullWidth
                      value={sapCityName || ''}
                      inputLabel="sap-city"
                      formik={formik}
                      name="sapCityName"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Dropdown
                      options={citiesData}
                      loading={citiesLoading}
                      placeholder="city"
                      inputLabel="city"
                      value={cityId || ''}
                      formik={formik}
                      name="cityId"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="pincode"
                      size="small"
                      fullWidth
                      value={pincode || ''}
                      inputLabel="pincode"
                      formik={formik}
                      name="pincode"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="latitude"
                      size="small"
                      fullWidth
                      value={latitude || ''}
                      inputLabel="latitude"
                      formik={formik}
                      name="latitude"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="longitude"
                      size="small"
                      fullWidth
                      value={longitude || ''}
                      inputLabel="longitude"
                      formik={formik}
                      name="longitude"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="address"
                      size="small"
                      fullWidth
                      value={address || ''}
                      inputLabel="address"
                      formik={formik}
                      name="address"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <TextInput
                      placeholder="landmark"
                      size="small"
                      fullWidth
                      value={landmark || ''}
                      inputLabel="landmark"
                      formik={formik}
                      name="landmark"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <SwitchInput
                      inputLabel="activeStatus"
                      leftLabel="inactive"
                      rightLabel="active"
                      value={activeStatus || 1}
                      formik={formik}
                      name="activeStatus"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Stack spacing={1.5} alignItems="center">
                      <SingleFileUpload
                        setFieldValue={setFieldValue}
                        inputLabel="image"
                        file={file}
                        error={!!errors.files}
                        onUpload={uploadFile}
                        onRemoveCallBack={onRemoveCallBack}
                      />
                      {errors.files && (
                        <p
                          className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall
                         MuiFormHelperText-contained css-xfx3vf-MuiFormHelperText-root"
                        >
                          {errors.files}
                        </p>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider variant="fullWidth" style={{ margin: '20px 0' }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row" className="btn-end">
                      <AnimateButton>
                        <Button type="submit" disabled={isPending} 
                        onClick={handleSave} variant="contained">
                          <FormattedMessage id="save" />
                        </Button>
                      </AnimateButton>
                    </Stack>
                  </Grid>
                </>
              )}
            </Grid>
          </MainCard>
        </form>
      )}
    </>
  );
}

CreateEditPrimaryCustomerMaster.propTypes = {
  closeModal: PropTypes.func.isRequired,
  activeEditId: PropTypes.bool,
};

CreateEditPrimaryCustomerMaster.defaultProps = {
  activeEditId: 0
};

export default CreateEditPrimaryCustomerMaster;
