import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getData, getStatus, getErrorMessage } from "@/store/slices/helpers/helpers";
import { clearMultipleData, fetchData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiUrls } from "@/store/api/constants";
import useAuth from "@/from-theme/hooks/useAuth";
import navs from '@/from-theme/routes/constants/nav';

export default function useNotification() {
  const { formatMessage } = useIntl();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [allowAudio, setAllowAudio] = useState(false); // Ensure user interaction for audio
  const dispatch = useDispatch();
  const state = useSelector((store) => store);
  const { userData } = useAuth();
  const navigate = useNavigate();
  const designation = userData?.data?.designationId;

  const notificationData = getData(state, apiKeys.getWebNotificationCount, []);
  const notificationStatus = getStatus(state, apiKeys.getWebNotificationCount, '');
  const notificationErrMsg = getErrorMessage(state, apiKeys.getWebNotificationCount, '');

  // Function to fetch notifications
  const getNotification = useCallback(() => {
    dispatch(fetchData({
      url: apiUrls.getWebNotificationCount,
      method: apiMethods.POST,
      key: apiKeys.getWebNotificationCount,
      failureMsg: formatMessage({ id: "failed-to-get-notification-count" }),
      body: { designationId: designation }
    }));
  }, [dispatch, formatMessage, designation]);

  // Auto-fetch notifications at intervals if the user is eligible
  useEffect(() => {
    let intervalId;
    const eligibleDesignations = [39, 281, 295, 297, 300, 301, 412, 415, 416, 419, 421, 594];

    if (eligibleDesignations.includes(designation)) {
      getNotification();
      intervalId = setInterval(getNotification, 300000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [designation, getNotification]);

  // Function to play notification sound
  const playAudio = async () => {
    if (!allowAudio) return; // Prevent auto-play error

    try {
      const audio = new Audio('/assets/audio/notification.wav');
      await audio.play();
    } catch (error) {
      console.error('Error playing audio:', error);
    }
  };

  // Update notification count and play sound if allowed
  useEffect(() => {
    if (notificationData) {
      const totalCount =
        (notificationData.planApprovalCount || 0) +
        (notificationData.planDeviationCount || 0) +
        (notificationData.claimCount || 0);
      
      setCount(totalCount);

      // Play sound only if user interaction is allowed
      if (totalCount > 0) {
        playAudio();
      }
    }
  }, [notificationData]);

  // Function to allow audio playback (user must click)
  const enableAudio = () => {
    setAllowAudio(true);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handlePlanApprovalIfoods = () => {
    navigate(navs.bOPlanApproval);
  };

  const handlePlanApprovalfoods = () => {
    navigate(navs.PlanApprovalFoods);
  };

  const handleClaimApproval = () => {
    navigate(navs.ClaimApprovalBOIF);
  };

  // Clear data when component unmounts
  useEffect(() => () => {
    dispatch(clearMultipleData({ key: apiKeys.divisionMaster }));
  }, [dispatch]);

  return useMemo(() => ({
    handleClose,
    handleToggle,
    count,
    setCount,
    open,
    setOpen,
    anchorRef,
    getNotification,
    notificationData,
    notificationStatus,
    notificationErrMsg,
    handlePlanApprovalIfoods,
    handlePlanApprovalfoods,
    handleClaimApproval,
    enableAudio,  // Expose this function for the UI
    allowAudio
  }), [
    handleClose,
    handleToggle,
    count,
    setCount,
    open,
    setOpen,
    anchorRef,
    getNotification,
    notificationData,
    notificationStatus,
    notificationErrMsg,
    handlePlanApprovalIfoods,
    handlePlanApprovalfoods,
    handleClaimApproval,
    enableAudio,
    allowAudio
  ]);
}
