import { RouterProvider } from "react-router-dom";
// Project Imports
import router from "@/from-theme/routes";
import ThemeCustomization from "@/from-theme/themes";
import Locales from "@/from-theme/components/Locales";
import RTLLayout from "@/from-theme/components/RTLLayout";
import ScrollTop from "@/from-theme/components/ScrollTop";
import Snackbar from "@/from-theme/components/@extended/Snackbar";
import Notistack from "@/from-theme/components/third-party/Notistack";
// Auth Provider
import { JWTProvider as AuthProvider } from "@/from-theme/contexts/JWTContext";
import useAuth from "@/from-theme/hooks/useAuth";
// Custom Hook for Session Timeout
import useSessionTimeout from "./useSessionTimeout";

const AppContent = () => {
  const { logout } = useAuth(); // Use auth logout function
  useSessionTimeout(1800000, logout); // 30 minutes (600000 ms)

  return (
    <>
      <Notistack>
        <RouterProvider router={router} />
        <Snackbar />
      </Notistack>
    </>
  );
};

const App = () => (
  <ThemeCustomization>
    <RTLLayout>
      <Locales>
        <ScrollTop>
          <AuthProvider>
            <AppContent />
          </AuthProvider>
        </ScrollTop>
      </Locales>
    </RTLLayout>
  </ThemeCustomization>
);

export default App;
