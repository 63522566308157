import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getStatus, getErrorMessage } from "@/store/slices/helpers/helpers";
import { clearData, fetchData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { initialValues } from '../constants/constants';
import { getFormattedPayload } from "@/common/helpers/helpers";

export default function useSecondaryCustomerMaster({getDistrict, getCity}) {
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [activeEditId, setActiveEditId] = useState(0);
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveSecCustomerMasterStatus = getStatus(state, apiKeys.saveSecCustomerMaster, '');
  const updateSecCustomerMasterStatus = getStatus(state, apiKeys.updateSecCustomerMaster, '');
  const secondaryCustomerMasterData = getData(state, apiKeys.secondaryCustomerMaster, []);
  const secondaryCustomerMasterStatus = getStatus(state, apiKeys.secondaryCustomerMaster, '');
  const secondaryCustomerMasterErrMsg = getErrorMessage(state, apiKeys.secondaryCustomerMaster, '');
  const deleteSecondaryCustomerMasterStatus =
    getStatus(state, apiKeys.deleteSecondaryCustomerMaster, '');

    const validationSchema = yup.object({
      stateId: yup.object().nullable(),
      districtId: yup.object().nullable(),
      cityId: yup.array().nullable(),
    });
  
    const formik = useFormik({
      initialValues,
      validationSchema,
      onSubmit: () => {
        getSecondaryCustomerMaster();
      }
    });
  
    formik.fields = validationSchema?.fields;
    formik.schema = validationSchema;
    const { values } = formik;
    const { stateId, districtId } = values;

  const getSecondaryCustomerMaster = useCallback(() => {
    dispatch(fetchData({
      url: apiUrls.secondaryCustomerMaster,
      method: apiMethods.GET,
      key: apiKeys.secondaryCustomerMaster,
      failureMsg: formatMessage({ id: "failed-to-get-secondary-customer-master" }),
    }))
  }, [dispatch, formatMessage])

  useEffect(() => {
    getSecondaryCustomerMaster()
    if (stateId) {
      let payload = 0;
      payload = getFormattedPayload({ stateId });
      getDistrict(payload);
    }
    if (districtId ) {
      let payload = 0;
      payload = getFormattedPayload({ districtId });
      getCity(payload);
    }
  }, [dispatch, getSecondaryCustomerMaster,getDistrict, getCity, districtId, stateId ]);

  useEffect(() => {
    if (deleteSecondaryCustomerMasterStatus === apiStatuses.succeeded) {
      getSecondaryCustomerMaster();
    }
  }, [deleteSecondaryCustomerMasterStatus, getSecondaryCustomerMaster])



  useEffect(() => () => {
    dispatch(clearData({ key: apiKeys.secondaryCustomerMaster }));
  }, [dispatch]);

  const onRowEdit = useCallback((id) => {
    setIsCreateEditModalOpen(true);
    setActiveEditId(id);
  }, [])
  const closeModal = useCallback(() => {
    setIsCreateEditModalOpen(false);
    setActiveEditId(0);
  }, []);

  useEffect(() => {
    if (saveSecCustomerMasterStatus === apiStatuses.succeeded) {
      closeModal();
      getSecondaryCustomerMaster();
    } 
  }, [saveSecCustomerMasterStatus, closeModal, getSecondaryCustomerMaster ]);

  useEffect(() => {
    if (updateSecCustomerMasterStatus === apiStatuses.succeeded) {
      closeModal(); 
      getSecondaryCustomerMaster();
    } 
  }, [updateSecCustomerMasterStatus, closeModal, getSecondaryCustomerMaster]);

  return useMemo(() => ({
    formik,
    values: formik.values,
    getSecondaryCustomerMaster,
    secondaryCustomerMasterData,
    secondaryCustomerMasterStatus,
    secondaryCustomerMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  }), [
    formik,
    getSecondaryCustomerMaster,
    secondaryCustomerMasterData,
    secondaryCustomerMasterStatus,
    secondaryCustomerMasterErrMsg,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal
  ])
}
