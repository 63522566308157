import { useFormik } from 'formik';
import * as yup from 'yup';
import { useCallback, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getData, getErrorMessage, getStatus } from '@/store/slices/helpers/helpers';
import { fetchData, clearMultipleData } from '@/store/slices/fetch-slice';
import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { initialValues } from '../constants/constants';
import { getFormattedPayload, getFormattedResponse } from '@/common/helpers/helpers';

function useCreateEditExpenseLimitMaster(activeEditId) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveExpenseLimitMasterStatus = getStatus(state, apiKeys.saveExpenseLimitMaster, '');
  const updateExpenseLimitMasterStatus = getStatus(state, apiKeys.updateExpenseLimitMaster, '');
  const saveExpenseLimitMasterLoading = apiStatuses.pending === saveExpenseLimitMasterStatus;

  const getEditExpenseLimitMasterData = getData(state, apiKeys.getEditExpenseLimitMaster, {});
  const getEditExpenseLimitMasterStatus = getStatus(state, apiKeys.getEditExpenseLimitMaster, '');
  const getEditExpenseLimitMasterErrMsg = getErrorMessage(
    state,
    apiKeys.getEditExpenseLimitMaster,
    formatMessage({ id: 'failed-to-get-selected-category-details' })
  );

  const validationSchema = yup.object({
    ratePerDay: yup.string()
    .matches(/^[0-9]+$/, formatMessage({ id: 'only-numerical-characters-allowed' }))
    .required(formatMessage({ id: 'this-field-is-required' })),
    limitPerDayKm: yup.string()
    .matches(/^[0-9]+$/, formatMessage({ id: 'only-numerical-characters-allowed' }))
    .required(formatMessage({ id: 'this-field-is-required' })),
    limitPerMonthKm: yup.string()
    .matches(/^[0-9]+$/, formatMessage({ id: 'only-numerical-characters-allowed' }))
    .required(formatMessage({ id: 'this-field-is-required' })),
    vehicleType: yup.object().required(formatMessage({ id: 'this-field-is-required' })),
    divisionId: yup.object().required(formatMessage({ id: 'this-field-is-required' })),
    elValidFrom: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    elValidTo: yup
      .string()
      .required(formatMessage({ id: 'this-field-is-required' }))
      .test('validTo', 'ValidTo date must be after ValidFrom date', function (value) {
        const { elValidFrom } = this.parent;
        if (!elValidFrom || !value) {
          return true;
        }
        return new Date(value) >= new Date(elValidFrom);
      }),
    activeStatus: yup.boolean().required(formatMessage({ id: 'this-field-is-required' }))
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (activeEditId) {
        updateExpenseLimitMaster(values);
      } else {
        saveExpenseLimitMaster(values);
      }
    }
  });

  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;

  const getEditExpenseLimitMaster = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getEditExpenseLimitMaster(activeEditId),
        method: apiMethods.GET,
        key: apiKeys.getEditExpenseLimitMaster,
        failureMsg: formatMessage({ id: 'failed-to-get-selected-expense-limit' })
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(() => {
    if (activeEditId) {
      getEditExpenseLimitMaster();
    }
  }, [activeEditId, getEditExpenseLimitMaster]);

  useEffect(() => {
    if (getEditExpenseLimitMasterStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getEditExpenseLimitMasterData));
    }
  }, [getEditExpenseLimitMasterStatus]);

  const saveExpenseLimitMaster = useCallback(
    (values) => {
      dispatch(
        fetchData({
          url: apiUrls.saveExpenseLimitMaster,
          method: apiMethods.POST,
          key: apiKeys.saveExpenseLimitMaster,
          successMsg: formatMessage({ id: 'expense-limit-is-saved-successfully' }),
          failureMsg: formatMessage({ id: 'expense-limit-is-failed-to-save' }),
          body: getFormattedPayload(values)
        })
      );
    },
    [dispatch, formatMessage]
  );

  const updateExpenseLimitMaster = useCallback(
    (values) => {
      dispatch(
        fetchData({
          url: apiUrls.updateExpenseLimitMaster,
          method: apiMethods.PUT,
          key: apiKeys.updateExpenseLimitMaster,
          successMsg: formatMessage({ id: 'expense-limit-is-updated-successfully' }),
          failureMsg: formatMessage({ id: 'expense-limit-is-failed-to-update' }),
          body: getFormattedPayload({ ...values, id: activeEditId })
        })
      );
    },
    [dispatch, formatMessage, activeEditId]
  );

  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: [apiKeys.saveExpenseLimitMaster, 
        apiKeys.updateExpenseLimitMaster, apiKeys.getEditExpenseLimitMaster] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      formik,
      values: formik.values,
      saveExpenseLimitMasterStatus,
      updateExpenseLimitMasterStatus,
      saveExpenseLimitMasterLoading,
      getEditExpenseLimitMasterData,
      getEditExpenseLimitMasterStatus,
      getEditExpenseLimitMasterErrMsg
    }),
    [
      formik,
      saveExpenseLimitMasterStatus,
      updateExpenseLimitMasterStatus,
      saveExpenseLimitMasterLoading,
      getEditExpenseLimitMasterData,
      getEditExpenseLimitMasterStatus,
      getEditExpenseLimitMasterErrMsg
    ]
  );
}

export default useCreateEditExpenseLimitMaster;
