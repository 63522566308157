import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getData, getStatus, getErrorMessage } from '@/store/slices/helpers/helpers';
import { clearData, fetchData } from '@/store/slices/fetch-slice';
import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
// import { getFormattedPayload } from '@/common/helpers/helpers';
// import * as yup from 'yup';
// import { useFormik } from "formik";
// import { initialValues } from "../constants/constants";


export default function useMaterial() {
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [activeEditId, setActiveEditId] = useState(0);
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveMaterialMasterStatus = getStatus(state, apiKeys.saveMaterialMaster, '');
  const updateMaterialMasterStatus = getStatus(state, apiKeys.updateMaterialMaster, '');
  const materialMasterData = getData(state, apiKeys.materialMaster, []);
  const materialMasterStatus = getStatus(state, apiKeys.materialMaster, '');
  const materialMasterErrMsg = getErrorMessage(state, apiKeys.materialMaster, '');
  const deleteMaterialStatus = getStatus(state, apiKeys.deleteMaterialMaster, '');

  // const validationSchema = yup.object({
  //   status: yup.array().nullable()
  // });

  // const formik = useFormik({
  //   initialValues,
  //   validationSchema,
  //   onSubmit: () => {
  //     getMaterial();
  //   }
  // });

  // formik.fields = validationSchema?.fields;
  // formik.schema = validationSchema;
  // const { values } = formik;
  // const { status } = values;

  const getMaterial = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.materialMaster,
        method: apiMethods.GET,
        key: apiKeys.materialMaster,
        failureMsg: formatMessage({ id: 'failed-to-get-material-master' }),
        // body:getFormattedPayload({ status})
        
      })
    );
  }, [dispatch, formatMessage, ]);

  useEffect(() => {
    getMaterial();
  }, [dispatch, getMaterial]);

  useEffect(() => {
    if (deleteMaterialStatus === apiStatuses.succeeded) {
      getMaterial();
    }
  }, [deleteMaterialStatus, getMaterial]);

  useEffect(
    () => () => {
      dispatch(clearData({ key: apiKeys.materialMaster }));
    },
    [dispatch]
  );

  const onRowEdit = useCallback((id) => {
    setIsCreateEditModalOpen(true);
    setActiveEditId(id);
  }, []);
  const closeModal = useCallback(() => {
    setIsCreateEditModalOpen(false);
    setActiveEditId(0);
  }, []);

  useEffect(() => {
    if (saveMaterialMasterStatus === apiStatuses.succeeded) {
      closeModal();
      getMaterial();
    }
  }, [saveMaterialMasterStatus, closeModal, getMaterial]);

  useEffect(() => {
    if (updateMaterialMasterStatus === apiStatuses.succeeded) {
      closeModal();
      getMaterial();
    }
  }, [updateMaterialMasterStatus, closeModal, getMaterial]);

  return useMemo(
    () => ({
      // formik,
      // values: formik.values,
      getMaterial,
      materialMasterData,
      materialMasterStatus,
      materialMasterErrMsg,
      isCreateEditModalOpen,
      setIsCreateEditModalOpen,
      onRowEdit,
      activeEditId,
      closeModal
    }),
    [
      // formik,
      getMaterial,
      materialMasterData,
      materialMasterStatus,
      materialMasterErrMsg,
      isCreateEditModalOpen,
      setIsCreateEditModalOpen,
      onRowEdit,
      activeEditId,
      closeModal
    ]
  );
}
