import { useFormik } from "formik";
import * as yup from 'yup';
import { useCallback, useMemo, useEffect } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getErrorMessage, getStatus } from "@/store/slices/helpers/helpers";
import { fetchData, clearData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";
import { initialValues } from "../constants/constants";
import { getFormattedPayload, getFormattedResponse } from "@/common/helpers/helpers";

function useCreateEditDropdownListMaster(activeEditId) {
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveDropdownlistMasterStatus = getStatus(state, apiKeys.saveDropdownlistMaster, '');
  const updateDropdownlistMasterStatus = getStatus(state, apiKeys.updateDropdownlistMaster, '');
  const saveDropdownlistMasterLoading = apiStatuses.pending === saveDropdownlistMasterStatus;
  const getEditDropdownlistMasterData = getData(state, apiKeys.getEditDropdownlistMaster, {});
  const getEditDropdownlistMasterStatus = getStatus(state, apiKeys.getEditDropdownlistMaster, "");
  const getEditDropdownlistMasterErrMsg = getErrorMessage(
    state,
    apiKeys.getEditDropdownlistMaster,
    formatMessage({ id: "failed-to-get-selected-dropdownlist-details" })
  );

  const validationSchema = yup.object({
    listShortName: yup.string().required(formatMessage({ id: "this-field-is-required" })),
    dropdownListName: yup.string().required(formatMessage({ id: "this-field-is-required" })),
    activeStatus: yup.boolean().required(formatMessage({ id: "this-field-is-required" })),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if(activeEditId){
        updateDropdownlistMaster(values)
      }else{
        saveDropdownlistMaster(values)
      }
    }
  });

  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;

  const getEditDropdownlistMaster = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getEditDropdownlistMaster(activeEditId),
        method: apiMethods.GET,
        key: apiKeys.getEditDropdownlistMaster,
        failureMsg: formatMessage({ id: "failed-to-get-selected-employees-details" }),
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(() => {
    if (activeEditId) {
      getEditDropdownlistMaster()
    }
  }, [activeEditId, getEditDropdownlistMaster]);

  useEffect(() => {
    if (getEditDropdownlistMasterStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getEditDropdownlistMasterData))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEditDropdownlistMasterStatus])


  const saveDropdownlistMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.saveDropdownlistMaster,
        method: apiMethods.POST,
        key: apiKeys.saveDropdownlistMaster,
        successMsg: formatMessage({ id: "dropdownlist-master-is-saved-successfully" }),
        failureMsg: formatMessage({ id:  "dropdownlist-master-is-failed-to-save" }),
        body: getFormattedPayload(values)
      })
    );
  }, [dispatch, formatMessage]);

  const updateDropdownlistMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.updateDropdownlistMaster,
        method: apiMethods.PUT,
        key: apiKeys.updateDropdownlistMaster,
        successMsg: formatMessage({ id: "dropdownlist-master-is-updated-successfully" }),
        failureMsg: formatMessage({ id: "dropdownlist-master-is-failed-to-update" }),
        body: getFormattedPayload({ ...values, id: activeEditId })
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(
    () => () => {
      dispatch(clearData({ key: apiKeys.getEditDropdownlistMaster }));
    },
    [dispatch]
  );


  return useMemo(() => ({
    formik,
    values: formik.values,
    saveDropdownlistMasterStatus,
    updateDropdownlistMasterStatus,
    saveDropdownlistMasterLoading,
    getEditDropdownlistMasterData,
    getEditDropdownlistMasterStatus,
    getEditDropdownlistMasterErrMsg,
  }), [
    formik,
    saveDropdownlistMasterStatus,
    updateDropdownlistMasterStatus,
    saveDropdownlistMasterLoading,
    getEditDropdownlistMasterData,
    getEditDropdownlistMasterStatus,
    getEditDropdownlistMasterErrMsg,
  ])
}

export default useCreateEditDropdownListMaster