import { BookOutlined, UserOutlined } from '@ant-design/icons';
import Dropdown from '../components/dropdown/dropdown';
import DropdownList from '../components/dropdownlist/dropdownlist';
import { requestedFeaturesAll, requestedMenuAll } 
from '../../../../from-theme/routes/constants/menuAccess';
import VerifyAccess from '@/common/components/verify-access/verify-access';

const { dropdownTabMasterKey, dropdownListTabMasterKey } = requestedMenuAll;
const { readKey } = requestedFeaturesAll;

export const dropdownMasterTabs = [
  {
    id: 'dropdown',
    tabName: 'dropdown',
    icon: <UserOutlined />,
    tabContent: (
      <VerifyAccess requestedMenu={dropdownTabMasterKey} requestedFeature={readKey}>
        <Dropdown />
      </VerifyAccess>
    )
  },
  {
    id: 'dropdown-list',
    tabName: 'dropdown-list',
    icon: <BookOutlined />,
    tabContent: (
      <VerifyAccess requestedMenu={dropdownListTabMasterKey} requestedFeature={readKey}>
        <DropdownList />
      </VerifyAccess>
    )
  }
];
