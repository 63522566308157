const navs = {
    employeeMaster: "/employee-master",
    userMaster: "/user-master",
    editEmployeeMaster: (id) => `/employee-master/edit/${id}`,
    editUserMaster: (id) => `/user-master/edit/${id}`,
    editIfdCompetitorMaster: (id) => `/ifd-competitor-master/edit/${id}`,
    editCompetitorOffers: (id) => `/competitor-offers/edit/${id}`,
    editDivisionMaster: (id) => `/division-master/edit/${id}`,
    editEmployeeMapping: (id) => `/employee-mapping/edit/${id}`,
    editOutletMaster: (id) => `/outlet-master/edit/${id}`,
    editPlantMaster: (id) => `/plant-master/edit/${id}`,
    editVenueMaster: (id) => `/venue-master/edit/${id}`,
    editProspectMaster: (id) => `/prospect-master/edit/${id}`,
    getEditMenuAccess: (id) => `/menu-access/edit/${id}`,
    editShiftMaster: (id) => `/shift-master/edit/${id}`,
    editPjpMaster: (id) => `/pjp-creation/edit/${id}`,
    viewBOPlanApproval: (id) =>`/plan-validation/edit/${id}`,
    viewBOPlan: `/plan-report`,
    viewBOPlanDetails: (id) =>`/plan-report/view/${id}`,
    viewClaimExpense: (id) =>`/claim-details-report/view/${id}`,
    viewBOPlanDevApproval: (id) =>`/plan-deviation-approval-backoffice/edit/${id}`,
    viewManagerPlanDevApproval: (id) =>`/plan-deviation-approval-manager/edit/${id}`,
    viewProspectApproval: (id) =>`/prospect-validation/edit/${id}`,
    viewClaimBOApproval: (id) =>`/approval-claim-backoffice/edit/${id}`,
    viewClaimExcApproval: (id) =>`/approval-claim-executive/edit/${id}`,
    viewSecondaryCustomer: `/approval-secondary-customer`,
    viewSecondaryCustomerApproval: (id) =>`/approval-secondary-customer/edit/${id}`,
    approveClaimManagerList:`/approval-claim-manager`,
    approveClaimManager:`/approval-claim-manager/view`,
    viewClaimManager: `/approval-claim-manager/edit`,
    viewClaimSmartForm: (id) =>`/claim-details-report/edit/${id}`,
    viewOrderDetails: (id) =>`/primary-order-report/view/${id}`,
    PrimaryOrderDetails: `/primary-order-report`,
    ClaimReportView: `/claim-details-report`,
    ClaimApprovalBOIF: `/approval-claim-backoffice`,
    ClaimApprovalExecutive: `/approval-claim-executive`,
    PlanDeviationApproveManager: `/plan-deviation-approval-manager`,
    divisionMaster: "/division-master",
    plantMaster: "/plant-master",
    login: "/login",
    attendanceReport: "/attendance-report",
    appTrackReport: "/app-track-report",
    travelReport: "/travel-report",
    prospectMaster: "/prospect-master",
    shiftMaster: "/shift-master",
    dropdown: "/dropdown-master",
    dropdownlist: "/dropdownlist",
    ifdCompetitorMaster: "/ifd-competitor-master",
    venueMaster: "/venue-master",
    menuMaster: "/menu-master",
    areas: "/area-master",
    outletMaster: "/outlet-master",
    PrimaryCustomerMaster: "/customer-master",
    competitorOffers: "/competitor-offers",
    companyMasterCreate: "/company-master/create",
    claimCreate: "/claim-creation/create",
    ifdCompetitorMasterCreate: "/ifd-competitor-master/create",
    fdCompetitorMasterCreate: "/fd-competitor-master/create",
    competitorOffersCreate: "/competitor-offers/create",
    divisionMasterCreate: "/division-master/create",
    primaryCustomerMasterCreate: "/primary-customer-master/create",
    employeeMasterCreate: "/employee-master/create",
    venueMasterCreate: "/venue-master/create",
    userMasterCreate: "/user-master/create",
    employeeMappingCreate: "/employee-mapping/create",
    userMappingCreate: "/user-mapping/create",
    plantMasterCreate: "/plant-master/create",
    menuAccessCreate: "/menu-access/create",
    shiftMasterCreate: "/shift-master/create",
    planCreationCreate: "/pjp-creation/create",
    outletMasterCreate: "/outlet-master/create",
    prospectMasterCreate: "/prospect-master/create",    
    planApprovalManager: "/plan-validation-manager", 
    viewPlanManager: (id) =>`/plan-validation-manager/edit/${id}`,  
    bOPlanApproval: "/plan-validation",   
    ifoodsPlanDeviation: "/plan-deviation-approval-backoffice",   
    PlanApprovalFoods: "/approval-foods",   
    claimCreation: "/claim-creation",   
    calendarEvent: "/calendar-event",   
    calendarEventCreate: "/calendar-event/create",   
    ProspectValidation: "/prospect-validation",   
    pjpMaster: "/pjp-creation",   
    terms: "/terms",   
    policy: "/policy",   
    deactivate: "/deactivate",   
 
    noAccess: (redirectedFrom) => `/no-access${redirectedFrom
        ? `?redirectedFrom=${redirectedFrom}` : ""}`,
}

export default navs;