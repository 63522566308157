import { useFormik } from "formik";
import * as yup from 'yup';
import { useCallback, useMemo, useEffect } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getErrorMessage, getStatus } from "@/store/slices/helpers/helpers";
import { fetchData, clearMultipleData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";
import { initialValues } from "../constants/constants";
import { getFormattedPayload, getFormattedResponse } from "@/common/helpers/helpers";

function useCreateEditEnergyPriceMaster(activeEditId) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveEnergyPriceMasterStatus = getStatus(state, apiKeys.saveEnergyPriceMaster, '');
  const updateEnergyPriceMasterStatus = getStatus(state, apiKeys.updateEnergyPriceMaster, '');
  const saveEnergyPriceMasterLoading = apiStatuses.pending === saveEnergyPriceMasterStatus;

  const getEditEnergyPriceMasterData = getData(state, apiKeys.getEditEnergyPriceMaster, {});
  const getEditEnergyPriceMasterStatus = getStatus(state, apiKeys.getEditEnergyPriceMaster, "");
  const getEditEnergyPriceMasterErrMsg = getErrorMessage(
    state,
    apiKeys.getEditEnergyPriceMaster,
    formatMessage({ id: "failed-to-get-selected-energyprice-details" })
  );

  const validationSchema = yup.object({
    energyPriceRange: yup.string().required(formatMessage({ id: "this-field-is-required" })),
    perKmPrice: yup.string().required(formatMessage({ id: "this-field-is-required" })),
    energyTypeId: yup.object().required(formatMessage({ id: "this-field-is-required" })),
    epValidFrom: yup.string().required(formatMessage({ id: "this-field-is-required" })),
    epValidTo: yup.string().required(formatMessage({ id: "this-field-is-required" })),
    activeStatus: yup.boolean().required(formatMessage({ id: "this-field-is-required" })),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if(activeEditId){
        updateEnergyPriceMaster(values)
      }else{
        saveEnergyPriceMaster(values)
      }
    }
  });

  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;
  
  const getEditEnergyPriceMaster = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getEditEnergyPriceMaster(activeEditId),
        method: apiMethods.GET,
        key: apiKeys.getEditEnergyPriceMaster,
        failureMsg: formatMessage({ id: "failed-to-get-selected-energyprice-details" }),
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(() => {
    if (activeEditId) {
      getEditEnergyPriceMaster()
    }
  }, [activeEditId, getEditEnergyPriceMaster]);

  useEffect(() => {
    if (getEditEnergyPriceMasterStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getEditEnergyPriceMasterData))
    }
  }, [getEditEnergyPriceMasterStatus])

  const saveEnergyPriceMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.saveEnergyPriceMaster,
        method: apiMethods.POST,
        key: apiKeys.saveEnergyPriceMaster,
        successMsg: formatMessage({ id: "energyprice-master-is-saved-successfully" }),
        failureMsg: formatMessage({ id:  "energyprice-master-is-failed-to-save" }),
        body: getFormattedPayload(values)
      })
    );
  }, [dispatch, formatMessage]);

  const updateEnergyPriceMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.updateEnergyPriceMaster,
        method: apiMethods.PUT,
        key: apiKeys.updateEnergyPriceMaster,
        successMsg: formatMessage({ id: "energyprice-master-is-updated-successfully" }),
        failureMsg: formatMessage({ id: "energyprice-master-is-failed-to-update" }),
        body: getFormattedPayload({ ...values, id: activeEditId })
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: [apiKeys.saveEnergyPriceMaster, 
        apiKeys.getEditEnergyPriceMaster] }));
    },
    [dispatch]
  );

  return useMemo(() => ({
    formik,
    values: formik.values,
    saveEnergyPriceMasterStatus,
    updateEnergyPriceMasterStatus,
    saveEnergyPriceMasterLoading,
    getEditEnergyPriceMasterData,
    getEditEnergyPriceMasterStatus,
    getEditEnergyPriceMasterErrMsg,
  }), [
    formik,
    saveEnergyPriceMasterStatus,
    updateEnergyPriceMasterStatus,
    saveEnergyPriceMasterLoading,
    getEditEnergyPriceMasterData,
    getEditEnergyPriceMasterStatus,
    getEditEnergyPriceMasterErrMsg,
  ])
}

export default useCreateEditEnergyPriceMaster