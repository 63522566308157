import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputLabel } from '@mui/material';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import "./date-input.scss";

export default function MonthYearInput({
  placeholder = '',
  size = 'small',
  inputLabel = '',
  value = '',
  onChange,
  name,
  formik,
  ...props
}) {
  const { formatMessage } = useIntl();

  const formikError = React.useMemo(() => {
    if (formik) {
      return {
        error: formik?.touched[name] && Boolean(formik?.errors[name]),
        helperText: formik?.touched[name] && formik?.errors[name],
      };
    }
    return {};
  }, [formik, name]);

  return (
    <>
      {!!inputLabel && (
        <InputLabel className="labelClr" required={!formik?.fields?.[name]?.spec?.optional}
         htmlFor={name}>
          <FormattedMessage id={inputLabel} />
        </InputLabel>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          {...props}
          views={['month', 'year']} // Only allow month and year selection
          format="MM/YYYY"
          style={{ width: '80%' }}
          size={size || 'small'}
          fullWidth
          placeholder={placeholder ? formatMessage({ id: placeholder }) : ''}
          value={value ? dayjs(value, 'YYYY-MM') : null}
          sx={{ height: '20px' }}
          className="date-input"
          onChange={(newValue) => {
            if (formik) {
              formik.setFieldValue(name, dayjs(newValue).format('YYYY-MM')); 
              // Store only year and month
            } else {
              onChange(dayjs(newValue).format('YYYY-MM'));
            }
          }}
          slotProps={{
            textField: {
              ...formikError,
              id: name,
            },
          }}
        />
      </LocalizationProvider>
    </>
  );
}

MonthYearInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  inputLabel: PropTypes.string,
  size: PropTypes.string,
  name: PropTypes.string,
  formik: PropTypes.object,
  onChange: PropTypes.func,
};

MonthYearInput.defaultProps = {
  placeholder: '',
  value: '',
  inputLabel: '',
  size: 'small',
  formik: null,
  name: '',
  onChange: () => {},
};
