import React from "react";
import {
  Button, DialogActions, DialogContent, Grid
} from '@mui/material';
import TextInput from "@/common/components/text-input/text-input";
import Dropdown from "@/common/components/dropdown/dropdown";
import SwitchInput from "@/common/components/switch-input/switch-input";
import { FormattedMessage } from 'react-intl';
import { apiStatuses } from "@/store/api/constants";
import useCreateEditSecondaryCustomerMaster from "./hooks/useCreateEditSecondaryCustomerMaster";
import Spinner from "@/common/components/spinner/spinner";
import ErrorInPage from "@/common/components/error-in-page/error-in-page";
import PropTypes from "prop-types";
import useGetDropdownListOptions from "@/common/hooks/useGetDropdownListMaster";

function CreateEditSecCustomerMaster({
  closeModal, activeEditId, 
}) {

  const { CustCategory, getDropdownListLoading } = useGetDropdownListOptions();
  const { values, formik, getEditSecCustomerMasterStatus, saveSecCustomerMasterStatus,
    updateSecCustomerMasterStatus,
    getEditSecCustomerMasterErrMsg } = useCreateEditSecondaryCustomerMaster({activeEditId,  });
    
  const {
    secCusCode, secCusName, secCusCategory, secCusGstNo, address, landmark, 
    subzoneId, activeStatus, cityId, districtId, contactPerson, contactNo, latitude, longitude
  } = values;

    const isPending = [saveSecCustomerMasterStatus,
       updateSecCustomerMasterStatus].includes(apiStatuses.pending);
  

  return (
    <>
      {getEditSecCustomerMasterStatus === apiStatuses.pending && activeEditId
        && <Spinner />}
      {getEditSecCustomerMasterStatus === apiStatuses.rejected && activeEditId && (
        <ErrorInPage message={getEditSecCustomerMasterErrMsg} />
      )}
      {(getEditSecCustomerMasterStatus === apiStatuses.succeeded || !activeEditId) &&
    <form onSubmit={formik.handleSubmit}>
      <DialogContent dividers>
        <Grid container spacing={1.25}>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <TextInput
              placeholder="sap-code" size="small" fullWidth
              value={secCusCode}
              inputLabel="sap-code"
              formik={formik}
              name="secCusCode"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <TextInput
              placeholder="secondary-customer-name" size="small" fullWidth
              value={secCusName}
              inputLabel="secondary-customer-name"
              formik={formik}
              name="secCusName"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <Dropdown
              options={CustCategory}
              loading={getDropdownListLoading}
              placeholder="customer-category"
              inputLabel="customer-category"
              value={secCusCategory}
              formik={formik}
              name="secCusCategory"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <TextInput
              placeholder="customer-gst" size="small" fullWidth
              value={secCusGstNo}
              inputLabel="customer-gst"
              formik={formik}
              name="secCusGstNo"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <TextInput
              placeholder="contact-person" size="small" fullWidth
              value={contactPerson}
              inputLabel="contact-person"
              formik={formik}
              name="contactPerson"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <TextInput
              placeholder="contact-number" size="small" fullWidth
              value={contactNo}
              inputLabel="contact-number"
              formik={formik}
              name="contactNo"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <TextInput
              placeholder="latitude" size="small" fullWidth
              value={latitude}
              inputLabel="latitude"
              formik={formik}
              name="latitude"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <TextInput
              placeholder="longitude" size="small" fullWidth
              value={longitude}
              inputLabel="longitude"
              formik={formik}
              name="longitude"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <TextInput
              placeholder="address" size="small" fullWidth
              value={address}
              inputLabel="address"
              rows={2}
              formik={formik}
              name="address"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <TextInput
              placeholder="landmark" size="small" fullWidth
              value={landmark}
              inputLabel="landmark"
              formik={formik}
              name="landmark"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <TextInput
              placeholder="sub-zone" size="small" fullWidth
              value={subzoneId}
              inputLabel="sub-zone"
              formik={formik}
              name="subzoneId"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <TextInput
              placeholder="district-name" size="small" fullWidth
              value={districtId}
              inputLabel="district-name"
              formik={formik}
              name="districtId"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <TextInput
              placeholder="city-name" size="small" fullWidth
              value={cityId}
              inputLabel="city-name"
              formik={formik}
              name="cityId"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <SwitchInput
              inputLabel="activeStatus"
              leftLabel="inactive"
              rightLabel="active"
              value={activeStatus}
              formik={formik}
              name="activeStatus"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={closeModal} disabled={isPending}
         variant="outlined" sx={{ mr: 1 }}>
          <FormattedMessage id={"back"} />
        </Button>
        {/* <AnimateButton>
          <Button type="submit" variant="contained" sx={{ mr: 1 }}>
            <FormattedMessage id={"save"} />
          </Button>
        </AnimateButton> */}
      </DialogActions>
    </form>
     }
     </>
  )
}

CreateEditSecCustomerMaster.propTypes = {
  closeModal: PropTypes.func.isRequired,
  activeEditId: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

CreateEditSecCustomerMaster.defaultProps = {
  activeEditId: 0,
};

export default CreateEditSecCustomerMaster;