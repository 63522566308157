import { useFormik } from "formik";
import * as yup from 'yup';
import { useCallback, useMemo, useEffect } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getErrorMessage, getStatus } from "@/store/slices/helpers/helpers";
import { fetchData, clearMultipleData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";
import { initialValues } from "../constants/constants";
import { getFormattedPayload, getFormattedResponse } from "@/common/helpers/helpers";

function useCreateEditEnergyTypeMaster(activeEditId) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveEnergyTypeMasterStatus = getStatus(state, apiKeys.saveEnergyTypeMaster, '');
  const updateEnergyTypeMasterStatus = getStatus(state, apiKeys.updateEnergyTypeMaster, '');
  const saveEnergyTypeMasterLoading = apiStatuses.pending === saveEnergyTypeMasterStatus;

  const getEditEnergyTypeMasterData = getData(state, apiKeys.getEditEnergyTypeMaster, {});
  const getEditEnergyTypeMasterStatus = getStatus(state, apiKeys.getEditEnergyTypeMaster, "");
  const getEditEnergyTypeMasterErrMsg = getErrorMessage(
    state,
    apiKeys.getEditEnergyTypeMaster,
    formatMessage({ id: "failed-to-get-selected-category-details" })
  );

  const validationSchema = yup.object({
    vehicleCatName: yup.string().required(formatMessage({ id: "this-field-is-required" })),
    vehicleCatCode: yup.string().required(formatMessage({ id: "this-field-is-required" })),
    transportModeId: yup.object().required(formatMessage({ id: "this-field-is-required" })),
    activeStatus: yup.boolean().required(formatMessage({ id: "this-field-is-required" })),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if(activeEditId){
        updateEnergyTypeMaster(values)
      }else{
        saveEnergyTypeMaster(values)
      }
    }
  });

  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;

  const getEditEnergyTypeMaster = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getEditEnergyTypeMaster(activeEditId),
        method: apiMethods.GET,
        key: apiKeys.getEditEnergyTypeMaster,
        failureMsg: formatMessage({ id: "failed-to-get-selected-energy-type" }),
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(() => {
    if (activeEditId) {
      getEditEnergyTypeMaster()
    }
  }, [activeEditId, getEditEnergyTypeMaster]);

  useEffect(() => {
    if (getEditEnergyTypeMasterStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getEditEnergyTypeMasterData))
    }
  }, [getEditEnergyTypeMasterStatus])

  const saveEnergyTypeMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.saveEnergyTypeMaster,
        method: apiMethods.POST,
        key: apiKeys.saveEnergyTypeMaster,
        successMsg: formatMessage({ id: "energy-type-is-saved-successfully" }),
        failureMsg: formatMessage({ id:  "energy-type-is-failed-to-save" }),
        body: getFormattedPayload(values)
      })
    );
  }, [dispatch, formatMessage]);

  const updateEnergyTypeMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.updateEnergyTypeMaster,
        method: apiMethods.PUT,
        key: apiKeys.updateEnergyTypeMaster,
        successMsg: formatMessage({ id: "energy-type-is-updated-successfully" }),
        failureMsg: formatMessage({ id: "energy-type-is-failed-to-update" }),
        body: getFormattedPayload({ ...values, id: activeEditId })
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: [apiKeys.saveEnergyTypeMaster, 
        apiKeys.getEditEnergyTypeMaster] }));
    },
    [dispatch]
  );

  return useMemo(() => ({
    formik,
    values: formik.values,
    saveEnergyTypeMasterStatus,
    updateEnergyTypeMasterStatus,
    saveEnergyTypeMasterLoading,
    getEditEnergyTypeMasterData,
    getEditEnergyTypeMasterStatus,
    getEditEnergyTypeMasterErrMsg,
  }), [
    formik,
    saveEnergyTypeMasterStatus,
    updateEnergyTypeMasterStatus,
    saveEnergyTypeMasterLoading,
    getEditEnergyTypeMasterData,
    getEditEnergyTypeMasterStatus,
    getEditEnergyTypeMasterErrMsg,
  ])
}

export default useCreateEditEnergyTypeMaster