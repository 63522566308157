import { Chip, Grid } from "@mui/material";
import EditAction from "../components/edit-action/edit-action";
import DeleteAction from "../components/delete-action/delete-action";
import VerifyAccess from "@/common/components/verify-access/verify-access";
import { requestedMenuAll, requestedFeaturesAll } from "@/from-theme/routes/constants/menuAccess";
const {subZoneTabMasterKey} = requestedMenuAll;
const {updateKey, deleteKey} = requestedFeaturesAll;

export const columns = ({onRowEdit, onRowDelete, deleteSubzoneMasterStatus,
  isUpdateDeleteAccess}) => ([...[
  {
    header: 's-no',
    accessorKey: 'subzoneId',
    cell: (value) => {
      const subzoneId = value.row.index + 1;
     return subzoneId;
    }
  },
  {
    header: 'activity-group-name',
    accessorKey: 'zoneName'
  },
  {
    header: 'department',
    accessorKey: 'districtName'
  },
  {
    header: 'designation',
    accessorKey: 'districtName'
  },
  {
    header: 'user',
    accessorKey: 'districtName'
  },
  {
    header: 'status',
    accessorKey: 'activeStatus',
    cell: (cell) => {
      switch (cell.getValue()) {
        case '0':
          return <Chip color="error" label="In-Active" size="small" variant="light" />;
        case '1':
          return <Chip color="success" label="Active" size="small" variant="light" />;

      }
    }
  }
], ...(isUpdateDeleteAccess ? [
  {
    header: 'action',
    id: "edit",
    cell: (props) => (
      <Grid container className="table-icon-actions-2">
        <VerifyAccess requestedMenu={subZoneTabMasterKey} requestedFeature={updateKey}>
        <Grid item>
          <EditAction
            {...props}
            onRowEdit={onRowEdit}
          />
        </Grid>
        </VerifyAccess>
        <VerifyAccess requestedMenu={subZoneTabMasterKey} requestedFeature={deleteKey}>
        <Grid item>
          <DeleteAction
            {...props}
            onRowDelete={onRowDelete} deleteSubzoneMasterStatus={deleteSubzoneMasterStatus}
          />
        </Grid>
        </VerifyAccess>
      </Grid>
    ),
    meta: {
      className: 'cell-center'
    }
  }
] : [])]);

export const reportFileName = "SubZone Master";
