// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  BankOutlined,
  BarChartOutlined,
  RadarChartOutlined,
  ContainerOutlined,
  EnvironmentOutlined,
  FileImageOutlined,
  FolderViewOutlined,
  FundProjectionScreenOutlined,
  IdcardOutlined,
  HeatMapOutlined,
  AreaChartOutlined,
  LayoutOutlined,
  ProfileOutlined,
  DatabaseOutlined,
  EnvironmentFilled,
  EditFilled,
  RedEnvelopeFilled,
  RobotFilled,
  CrownFilled,
  ProfileFilled,
  GithubFilled,
  SlackSquareFilled,
  NotificationFilled,
  HomeFilled,
  SwitcherFilled,
  ExperimentFilled,
  DribbbleCircleFilled,
  FolderOpenFilled,
  ReconciliationFilled,
  CodeSandboxSquareFilled,
  SisternodeOutlined,
  CarFilled,
  QqCircleFilled,
  UserSwitchOutlined,
  ShopFilled,
  ScheduleOutlined
} from '@ant-design/icons';
import { requestedFeaturesAll, requestedMenuAll } from '../routes/constants/menuAccess';
// import { approvalsMenus } from './constants/constants';

// import VerifyMenuAccess from '../../common/components/verify-access/verify-menu-access';

// icons
const icons = { BankOutlined , DatabaseOutlined, HomeFilled, EditFilled, FolderOpenFilled, 
  ReconciliationFilled, CrownFilled, NotificationFilled, RedEnvelopeFilled, ShopFilled,
  DribbbleCircleFilled, GithubFilled, ProfileFilled, RobotFilled, QqCircleFilled, ExperimentFilled,
  CodeSandboxSquareFilled, SlackSquareFilled, SwitcherFilled,   BarChartOutlined,
  RadarChartOutlined, HeatMapOutlined, AreaChartOutlined, ContainerOutlined, EnvironmentOutlined,
  UserSwitchOutlined, SisternodeOutlined, ScheduleOutlined, CarFilled, EnvironmentFilled,
  FileImageOutlined, FolderViewOutlined, FundProjectionScreenOutlined, IdcardOutlined,
  LayoutOutlined, ProfileOutlined,  };

const {
  dashboardKey,
  appTrackReportKey,
  companyMasterKey,
  glMasterKey,
  planValidationKey,
  approvalClaimBOKey,
  bankMasterKey,
  claimMasterKey,
  ifdCompetitorMasterKey,
  fdCompetitorMasterKey,
  competitorOffersKey,
  customerMasterKey,
  divisionMasterKey,
  dropdownMasterKey,
  locationMasterKey,
  menuMasterKey,
  outletMasterKey,
  planApplicableMasterKey,
  planCreationKey,
  claimCreationKey,
  plantMasterKey,
  productMasterKey,
  prospectMasterKey,
  prospectValidationMasterKey,
  roleMasterKey,
  shiftMasterKey,
  userMasterKey,
  attendanceReportKey,
  venueMasterKey,
  zoneMasterKey,
  travelReportKey,
  ifoodsPlanReportKey,
  outletVisitReportKey,
  prospectReportKey,
  primeCustReportKey,
  competitorReportKey,
  secondCustReportKey,
  planDeviationBOKey,
  // approvalFoodsMasterKey,
  secondaryOrderReportKey,
  primaryCustomerVisitReportKey,
  secondaryOutletVisitReportKey,
  mapViewReportKey,
  planDeviationReportKey,
  claimDetailsReportKey,
  claimExpenseReportKey,
  travelClaimReportKey,
  pjpReportKey,
  primaryOrderReportKey,
  venueMappingReportKey,
  marketPromotionReportKey,
  settingsKey,
  commonNotificationKey,
  calendarEventKey,
  primeMatMappingKey,
  planValidationManagerKey,
  foodsDashboardKey, 
  ifoodsDashboardKey,
  eventMasterKey,
  planDeviationManagerKey,
  approvalClaimExecKey,
  approvalClaimManagerKey,
  claimFrequencyTypeKey,
  claimFrequencyMasterKey,
  approvalSecCustomerKey,
  claimLocationCategoryKey,
  schemesCategoryKey,
  targetKey
} = requestedMenuAll;
const { readKey } = requestedFeaturesAll;

const Dashboard = [dashboardKey];
const AnalyticsDashboard = [foodsDashboardKey, ifoodsDashboardKey];
const Masters = [companyMasterKey, bankMasterKey, claimMasterKey,ifdCompetitorMasterKey,
  competitorOffersKey, fdCompetitorMasterKey, schemesCategoryKey,
  customerMasterKey,divisionMasterKey,dropdownMasterKey,locationMasterKey,menuMasterKey,
  outletMasterKey,planApplicableMasterKey,plantMasterKey,productMasterKey,prospectMasterKey,
  roleMasterKey,shiftMasterKey,userMasterKey,venueMasterKey,zoneMasterKey, glMasterKey,
  settingsKey, primeMatMappingKey, eventMasterKey, claimFrequencyMasterKey, claimFrequencyTypeKey,
  claimLocationCategoryKey,  ];
//const approvalsGroup = [approvalFoodsMasterKey];
const Creation = [planCreationKey, claimCreationKey, calendarEventKey, targetKey];
const Validation = [prospectValidationMasterKey, planValidationKey, planDeviationBOKey,
  approvalClaimBOKey, commonNotificationKey, planValidationManagerKey, planDeviationManagerKey,
  approvalClaimExecKey, approvalClaimManagerKey, approvalSecCustomerKey];
const Reports = [attendanceReportKey,travelReportKey,ifoodsPlanReportKey,outletVisitReportKey,
  prospectReportKey,primeCustReportKey,competitorReportKey,secondCustReportKey, appTrackReportKey,
  mapViewReportKey, claimDetailsReportKey, claimExpenseReportKey, travelClaimReportKey,
  secondaryOutletVisitReportKey, secondaryOrderReportKey, primaryCustomerVisitReportKey, 
  pjpReportKey, primaryOrderReportKey, venueMappingReportKey, marketPromotionReportKey
];

// ==============================|| MENU ITEMS - PAGES ||============================== //

const pages = {
  id: 'group-pages',
  type: 'group',
  children: [

    ...(Dashboard.length >= 1 ? [
      {
        icon: icons.BankOutlined,
        id: 'dashboard',
        title: <FormattedMessage id="dashboard" />,
        type: 'item',
        url: '/dashboard',
        target: false,
        requestedMenu: dashboardKey,
        requestedFeature: readKey
      }
    ] : []),
    ...(AnalyticsDashboard.length >= 1 ? [
          {
            id: 'analytics-dashboard',
            title: <FormattedMessage id="analytics-dashboard" />,
            type: 'collapse',
            icon: icons.EditFilled,
            allMenus: AnalyticsDashboard,
            children: [
              {
                id: 'foods-dashboard',
                title: <FormattedMessage id="foods-dashboard" />,
                type: 'item',
                icon: icons.CarFilled,
                url: '/foods-dashboard',
                target: false,
                requestedMenu: foodsDashboardKey,
                requestedFeature: readKey
              },
              {
                id: 'ifoods-dashboard',
                title: <FormattedMessage id="ifoods-dashboard" />,
                type: 'item',
                icon: icons.CarFilled,
                url: '/ifoods-dashboard',
                target: false,
                requestedMenu: ifoodsDashboardKey,
                requestedFeature: readKey
              }
            ]
          },
        ] : []),
    ...(Masters.length >= 1 ? [
    {
      id: 'masters',
      title: <FormattedMessage id="masters" />,
      type: 'collapse',
      // url: '/',
      icon: icons.DatabaseOutlined,
      allMenus: Masters,
      children: [
        {
          id: 'bank-master',
          title: <FormattedMessage id="bank-master" />,
          type: 'item',
          icon:icons.BankOutlined,
          url: '/bank-master',
          target: false,
          requestedMenu: bankMasterKey,
          requestedFeature: readKey
        },
        {
          id: 'company-master',
          title: <FormattedMessage id="company-master" />,
          type: 'item',
          icon:icons.ScheduleOutlined,
          url: '/company-master',
          target: false,
          requestedMenu: companyMasterKey,
          requestedFeature: readKey
        },
        {
          id: 'claim-location-category',
          title: <FormattedMessage id="claim-location-category" />,
          type: 'item',
          icon:icons.ScheduleOutlined,
          url: '/claim-location-category',
          target: false,
          requestedMenu: claimLocationCategoryKey,
          requestedFeature: readKey
        },
        {
          id: 'claim-frequency-type',
          title: <FormattedMessage id="claim-frequency-type" />,
          type: 'item',
          icon:icons.ScheduleOutlined,
          url: '/claim-frequency-type',
          target: false,
          requestedMenu: claimFrequencyTypeKey,
          requestedFeature: readKey
        },
        {
          id: 'claim-frequency-master',
          title: <FormattedMessage id="claim-frequency-master" />,
          type: 'item',
          icon:icons.ScheduleOutlined,
          url: '/claim-frequency-master',
          target: false,
          requestedMenu: claimFrequencyMasterKey,
          requestedFeature: readKey
        },
        {
          id: 'competitor-offers',
          title: <FormattedMessage id="competitor-offers" />,
          type: 'item',
          icon:icons.NotificationFilled,
          url: '/competitor-offers',
          target: false,
          requestedMenu: competitorOffersKey,
          requestedFeature: readKey
        },
        {
          id: 'claim-master',
          title: <FormattedMessage id="claim-master" />,
          type: 'item',
          icon:icons.RedEnvelopeFilled,
          url: '/claim-master',
          target: false,
          requestedMenu: claimMasterKey,
          requestedFeature: readKey
        },
        {
          id: 'customer-master',
          title: <FormattedMessage id="customer-master" />,
          type: 'item',
          icon:icons.ProfileFilled,
          url: '/customer-master',
          target: false,
          requestedMenu: customerMasterKey,
          requestedFeature: readKey
        },
        {
          id: 'division-master',
          title: <FormattedMessage id="division-master" />,
          type: 'item',
          icon:icons.ScheduleOutlined,
          url: '/division-master',
          target: false,
          requestedMenu: divisionMasterKey,
          requestedFeature: readKey
        },
        {
          id: 'dropdown-master',
          title: <FormattedMessage id="dropdown-master" />,
          type: 'item',
          icon:icons.SwitcherFilled,
          url: '/dropdown-master',
          target: false,
          requestedMenu: dropdownMasterKey,
          requestedFeature: readKey
        },
        {
          id: 'event-master',
          title: <FormattedMessage id="event-master" />,
          type: 'item',
          url: '/event-master',
          icon:icons.SwitcherFilled,
          target: false,
          requestedMenu: eventMasterKey,
          requestedFeature: readKey
        },
        {
          id: 'fd-competitor-master',
          title: <FormattedMessage id="fd-competitor-master" />,
          type: 'item',
          icon:icons.CrownFilled,
          url: '/fd-competitor-master',
          target: false,
          requestedMenu: fdCompetitorMasterKey,
          requestedFeature: readKey
        },
        {
          id: 'gl-master',
          title: <FormattedMessage id="gl-master" />,
          type: 'item',
          icon:icons.NotificationFilled,
          url: '/gl-master',
          target: false,
          requestedMenu: glMasterKey,
          requestedFeature: readKey
        },
        {
          id: 'ifd-competitor-master',
          title: <FormattedMessage id="ifd-competitor-master" />,
          type: 'item',
          icon:icons.CrownFilled,
          url: '/ifd-competitor-master',
          target: false,
          requestedMenu: ifdCompetitorMasterKey,
          requestedFeature: readKey
        },
        {
          id: 'location-master',
          title: <FormattedMessage id="location-master" />,
          type: 'item',
          icon:icons.EnvironmentFilled,
          url: '/location-master',
          target: false,
          requestedMenu: locationMasterKey,
          requestedFeature: readKey
        },
        {
          id: 'menu-master',
          title: <FormattedMessage id="menu-master" />,
          type: 'item',
          icon:icons.SwitcherFilled,
          url: '/menu-master',
          target: false,
          requestedMenu: menuMasterKey,
          requestedFeature: readKey
        },
        {
          id: 'outlet-master',
          title: <FormattedMessage id="outlet-master" />,
          icon:icons.HomeFilled,
          type: 'item',
          url: '/outlet-master',
          target: false,
          requestedMenu: outletMasterKey,
          requestedFeature: readKey
        },
        {
          id: 'plant-master',
          title: <FormattedMessage id="plant-master" />,
          type: 'item',
          icon:icons.ExperimentFilled,
          url: '/plant-master',
          target: false,
          requestedMenu: plantMasterKey,
          requestedFeature: readKey
        },
        {
          id: 'plan-applicable',
          title: <FormattedMessage id="plan-applicable" />,
          type: 'item',
          icon:icons.CarFilled,
          url: '/plan-applicable',
          target: false,
          requestedMenu: planApplicableMasterKey,
          requestedFeature: readKey
        },
        {
          id: 'product-master',
          title: <FormattedMessage id="product-master" />,
          type: 'item',
          icon:icons.CodeSandboxSquareFilled,
          url: '/product-master',
          target: false,
          requestedMenu: productMasterKey,
          requestedFeature: readKey
        },
        {
          id: 'prospect-master',
          title: <FormattedMessage id="prospect-master" />,
          type: 'item',
          icon:icons.DribbbleCircleFilled,
          url: '/prospect-master',
          target: false,
          requestedMenu: prospectMasterKey,
          requestedFeature: readKey
        },
        {
          id: 'primary-material-mapping',
          title: <FormattedMessage id="primary-material-mapping" />,
          type: 'item',
          icon:icons.SlackSquareFilled,
          url: '/primary-material-mapping',
          target: false,
          requestedMenu: primeMatMappingKey,
          requestedFeature: readKey
        },
        {
          id: 'role-master',
          title: <FormattedMessage id="role-master" />,
          type: 'item',
          icon:icons.RobotFilled,
          url: '/role-master',
          target: false,
          requestedMenu: roleMasterKey,
          requestedFeature: readKey
        },
        {
          id: 'settings',
          title: <FormattedMessage id="settings" />,
          type: 'item',
          icon:icons.SlackSquareFilled,
          url: '/settings',
          target: false,
          requestedMenu: settingsKey,
          requestedFeature: readKey
        },
        {
          id: 'shift-master',
          title: <FormattedMessage id="shift-master" />,
          type: 'item',
          icon:icons.QqCircleFilled,
          url: '/shift-master',
          target: false,
          requestedMenu: shiftMasterKey,
          requestedFeature: readKey
        },
        {
          id: 'schemes-category',
          title: <FormattedMessage id="schemes-category" />,
          type: 'item',
          icon:icons.ScheduleOutlined,
          url: '/schemes-category',
          target: false,
          requestedMenu: schemesCategoryKey,
          requestedFeature: readKey
        },
        {
          id: 'user-master',
          title: <FormattedMessage id="user-master" />,
          type: 'item',
          icon:icons.UserSwitchOutlined,
          url: '/user-master',
          target: false,
          requestedMenu: userMasterKey,
          requestedFeature: readKey
        },
        {
          id: 'venue-master',
          title: <FormattedMessage id="venue-master" />,
          type: 'item',
          icon:icons.ShopFilled,
          url: '/venue-master',
          target: false,
          requestedMenu: venueMasterKey,
          requestedFeature: readKey
        },
        {
          id: 'zone-master',
          title: <FormattedMessage id="zone-master" />,
          type: 'item',
          icon:icons.SlackSquareFilled,
          url: '/zone-master',
          target: false,
          requestedMenu: zoneMasterKey,
          requestedFeature: readKey
        },
   
      
      ]
    }
  ] : []),
    // {
    //   id: approvalsMenus,
    //   title: <FormattedMessage id="approval" />,
    //   type: 'collapse',
    //   // url: '/',
    //   icon: icons.ScheduleOutlined,
    //   allMenus: approvalsGroup,
    //   children: [
    //     {
    //       id: 'approval-foods',
    //       title: <FormattedMessage id="foods-plan-approval" />,
    //       type: 'item',
    //       icon:icons.ScheduleOutlined,
    //       url: '/approval-foods',
    //       target: false,
    //       requestedMenu: approvalFoodsMasterKey,
    //       requestedFeature: readKey
    //     },
      
    //   ]
    // }, 
  ...(Creation.length >= 1 ? [
    {
      id: 'creation',
      title: <FormattedMessage id="creation" />,
      type: 'collapse',
      // url: '/',
      icon: icons.EditFilled,
      allMenus: Creation,
      children: [
        {
          id: 'pjp-creation',
          title: <FormattedMessage id="pjp-creation" />,
          type: 'item',
          icon:icons.CarFilled,
          url: '/pjp-creation',
          target: false,
          requestedMenu: planCreationKey,
          requestedFeature: readKey
        },
        {
          id: 'calendar-event',
          title: <FormattedMessage id="calendar-event" />,
          type: 'item',
          icon:icons.CarFilled,
          url: '/calendar-event',
          target: false,
          requestedMenu: calendarEventKey,
          requestedFeature: readKey
        },
        {
          id: 'claim-creation',
          title: <FormattedMessage id="claim-creation" />,
          type: 'item',
          icon:icons.RedEnvelopeFilled,
          url: '/claim-creation',
          target: false,
          requestedMenu: claimCreationKey,
          requestedFeature: readKey
        },
        {
          id: 'target',
          title: <FormattedMessage id="target" />,
          type: 'item',
          icon:icons.RedEnvelopeFilled,
          url: '/target',
          target: false,
          requestedMenu: targetKey,
          requestedFeature: readKey
        }
      ]
    }
  ] : []),
  ...(Validation.length >= 1 ? [
    {
      id: 'validation',
      title: <FormattedMessage id="validation" />,
      type: 'collapse',
      // url: '/',
      icon: icons.ReconciliationFilled,
      allMenus: Validation,
      children: [
        {
          id: 'plan-validation',
          title: <FormattedMessage id="plan-validation" />,
          type: 'item',
          icon:icons.ScheduleOutlined,
          url: '/plan-validation',
          target: false,
          requestedMenu: planValidationKey,
          requestedFeature: readKey
        },
        {
          id: 'plan-validation-manager',
          title: <FormattedMessage id="plan-validation-manager" />,
          type: 'item',
          icon:icons.ScheduleOutlined,
          url: '/plan-validation-manager',
          target: false,
          requestedMenu: planValidationManagerKey,
          requestedFeature: readKey
        },
        {
          id: 'plan-deviation-approval-backoffice',
          title: <FormattedMessage id="plan-deviation-validation-backoffice" />,
          type: 'item',
          icon:icons.ScheduleOutlined,
          url: '/plan-deviation-approval-backoffice',
          target: false,
          requestedMenu: planDeviationBOKey,
          requestedFeature: readKey
        },
        {
          id: 'plan-deviation-approval-manager',
          title: <FormattedMessage id="plan-deviation-validation-manager" />,
          type: 'item',
          icon:icons.ScheduleOutlined,
          url: '/plan-deviation-approval-manager',
          target: false,
          requestedMenu: planDeviationManagerKey,
          requestedFeature: readKey
        },
        {
          id: 'prospect-validation',
          title: <FormattedMessage id="ifoods-prospect-validation" />,
          type: 'item',
          icon:icons.DribbbleCircleFilled,
          url: '/prospect-validation',
          target: false,
          requestedMenu: prospectValidationMasterKey,
          requestedFeature: readKey
        },
        {
          id: 'approval-secondary-customer',
          title: <FormattedMessage id="approval-secondary-customer" />,
          type: 'item',
          icon:icons.RedEnvelopeFilled,
          url: '/approval-secondary-customer',
          target: false,
          requestedMenu: approvalSecCustomerKey,
          requestedFeature: readKey
        },
        {
          id: 'approval-claim-backoffice',
          title: <FormattedMessage id="claim-approval-backoffice" />,
          type: 'item',
          icon:icons.RedEnvelopeFilled,
          url: '/approval-claim-backoffice',
          target: false,
          requestedMenu: approvalClaimBOKey,
          requestedFeature: readKey
        },
        {
          id: 'approval-claim-executive',
          title: <FormattedMessage id="claim-approval-executive" />,
          type: 'item',
          icon:icons.RedEnvelopeFilled,
          url: '/approval-claim-executive',
          target: false,
          requestedMenu: approvalClaimExecKey,
          requestedFeature: readKey
        },
        {
          id: 'approval-claim-manager',
          title: <FormattedMessage id="claim-approval-manager" />,
          type: 'item',
          icon:icons.RedEnvelopeFilled,
          url: '/approval-claim-manager',
          target: false,
          requestedMenu: approvalClaimManagerKey,
          requestedFeature: readKey
        },
        {
          id: 'common-notification',
          title: <FormattedMessage id="common-notification" />,
          type: 'item',
          icon:icons.RedEnvelopeFilled,
          url: '/common-notification',
          target: false,
          requestedMenu: commonNotificationKey,
          requestedFeature: readKey
        }
      ]
    }
  ] : []),
  ...(Reports.length >= 1 ? [
    {
      id: 'reports',
      title: <FormattedMessage id="reports" />,
      type: 'collapse',
      // url: '/',
      icon: icons.FolderOpenFilled,
      allMenus: Reports,
      children: [
        {
          id: 'attendance-report',
          icon: icons.BarChartOutlined,
          title: <FormattedMessage id="attendance-report" />,
          type: 'item',
          url: '/attendance-report',
          target: false,
          requestedMenu: attendanceReportKey,
          requestedFeature: readKey
        },
        {
          id: 'app-track-report',
          icon: icons.RadarChartOutlined,
          title: <FormattedMessage id="app-track-report" />,
          type: 'item',
          url: '/app-track-report',
          target: false,
          requestedMenu: appTrackReportKey,
          requestedFeature: readKey
        },
        {
          id: 'competitor-report',
          title: <FormattedMessage id="competitor-report" />,
          type: 'item',
          icon:icons.GithubFilled,
          url: '/competitor-report',
          target: false,
          requestedMenu: competitorReportKey,
          requestedFeature: readKey
        },
        {
          id: 'claim-details-report',
          title: <FormattedMessage id="claim-details-report" />,
          type: 'item',
          icon:icons.HeatMapOutlined,
          url: '/claim-details-report',
          target: false,
          requestedMenu: claimDetailsReportKey,
          requestedFeature: readKey
        },
        {
          id: 'claim-expense-report',
          title: <FormattedMessage id="claim-expense-report" />,
          type: 'item',
          icon:icons.AreaChartOutlined,
          url: '/claim-expense-report',
          target: false,
          requestedMenu: claimExpenseReportKey,
          requestedFeature: readKey
        },
        {
          id: 'pjp-report',
          title: <FormattedMessage id="pjp-report" />,
          type: 'item',
          icon:icons.CarFilled,
          url: '/pjp-report',
          target: false,
          requestedMenu: pjpReportKey,
          requestedFeature: readKey
        },
        {
          id: 'plan-details-report',
          title: <FormattedMessage id="plan-details-report" />,
          type: 'item',
          icon:icons.CarFilled,
          url: '/plan-details-report',
          target: false,
          requestedMenu: ifoodsPlanReportKey,
          requestedFeature: readKey
        },
        {
          id: 'plan-report',
          title: <FormattedMessage id="plan-report" />,
          type: 'item',
          icon:icons.ContainerOutlined,
          url: '/plan-report',
          target: false,
          requestedMenu: ifoodsPlanReportKey,
          requestedFeature: readKey
        },
        {
          id: 'plan-deviation-report',
          title: <FormattedMessage id="plan-deviation-report" />,
          type: 'item',
          icon:icons.DatabaseOutlined,
          url: '/plan-deviation-report',
          target: false,
          requestedMenu: planDeviationReportKey,
          requestedFeature: readKey
        },
        {
          id: 'map-view',
          title: <FormattedMessage id="map-view" />,
          type: 'item',
          icon:icons.EnvironmentOutlined,
          url: '/map-view',
          target: false,
          requestedMenu: mapViewReportKey,
          requestedFeature: readKey
        },
        // {
        //   id: 'map',
        //   title: <FormattedMessage id="map" />,
        //   type: 'item',
        //   icon:icons.HomeFilled,
        //   url: '/map',
        //   target: false,
        //   requestedMenu: mapViewReportKey,
        //   requestedFeature: readKey
        // },
        {
          id: 'outlet-visit-report',
          title: <FormattedMessage id="outlet-visit-report" />,
          type: 'item',
          icon:icons.FileImageOutlined,
          url: '/outlet-visit-report',
          target: false,
          requestedMenu: outletVisitReportKey,
          requestedFeature: readKey
        },
        {
          id: 'primary-customer-report',
          title: <FormattedMessage id="primary-customer-report" />,
          type: 'item',
          icon:icons.ProfileFilled,
          url: '/primary-customer-report',
          target: false,
          requestedMenu: primeCustReportKey,
          requestedFeature: readKey
        },
        {
          id: 'primary-customer-visit-report',
          title: <FormattedMessage id="primary-customer-visit-report" />,
          type: 'item',
          icon:icons.FolderViewOutlined,
          url: '/primary-customer-visit-report',
          target: false,
          requestedMenu: primaryCustomerVisitReportKey,
          requestedFeature: readKey
        },
        {
          id: 'primary-order-report',
          title: <FormattedMessage id="primary-order-report" />,
          type: 'item',
          icon:icons.FundProjectionScreenOutlined,
          url: '/primary-order-report',
          target: false,
          requestedMenu: primaryOrderReportKey,
          requestedFeature: readKey
        },
        {
          id: 'secondary-customer-report',
          title: <FormattedMessage id="secondary-customer-report" />,
          type: 'item',
          icon:icons.IdcardOutlined,
          url: '/secondary-customer-report',
          target: false,
          requestedMenu: secondCustReportKey,
          requestedFeature: readKey
        },
        {
          id: 'secondary-outlet-visit-report',
          title: <FormattedMessage id="secondary-outlet-visit-report" />,
          type: 'item',
          icon:icons.LayoutOutlined,
          url: '/secondary-outlet-visit-report',
          target: false,
          requestedMenu: secondaryOutletVisitReportKey,
          requestedFeature: readKey
        },
        {
          id: 'secondary-order-report',
          title: <FormattedMessage id="secondary-order-report" />,
          type: 'item',
          icon:icons.HomeFilled,
          url: '/secondary-order-report',
          target: false,
          requestedMenu: secondaryOrderReportKey,
          requestedFeature: readKey
        },
        {
          id: 'prospect-report',
          title: <FormattedMessage id="prospect-report" />,
          type: 'item',
          icon:icons.DribbbleCircleFilled,
          url: '/prospect-report',
          target: false,
          requestedMenu: prospectReportKey,
          requestedFeature: readKey
        },
        
        {
          id: 'travel-claim-report',
          title: <FormattedMessage id="travel-claim-report" />,
          type: 'item',
          icon:icons.ProfileOutlined,
          url: '/travel-claim-report',
          target: false,
          requestedMenu: travelClaimReportKey,
          requestedFeature: readKey
        },
        {
          id: 'travel-report',
          title: <FormattedMessage id="travel-report" />,
          type: 'item',
          icon:icons.CarFilled,
          url: '/travel-report',
          target: false,
          requestedMenu: travelReportKey,
          requestedFeature: readKey
        },
        {
          id: 'venue-mapping-report',
          title: <FormattedMessage id="venue-mapping-report" />,
          type: 'item',
          icon:icons.CarFilled,
          url: '/venue-mapping-report',
          target: false,
          requestedMenu: venueMappingReportKey,
          requestedFeature: readKey
        },
        {
          id: 'market-promotion-report',
          title: <FormattedMessage id="market-promotion-report" />,
          type: 'item',
          icon:icons.CarFilled,
          url: '/market-promotion-report',
          target: false,
          requestedMenu: marketPromotionReportKey,
          requestedFeature: readKey
        },

      ]
    }
  ] : []),
  ]
};

export default pages;
