import { Chip, Grid } from "@mui/material";
import EditAction from "../components/edit-action/edit-action";
import DeleteAction from "../components/delete-action/delete-action";
import dayjs from 'dayjs';
import VerifyAccess from "@/common/components/verify-access/verify-access";
import { requestedMenuAll, requestedFeaturesAll } from "@/from-theme/routes/constants/menuAccess";
import { dateString } from "@/common/components/TableView/helpers/helpers";
const {allowanceTabMasterKey} = requestedMenuAll;
const {updateKey, deleteKey} = requestedFeaturesAll;

export const columns = ({ onRowEdit, onRowDelete, deleteAllowanceMasterStatus,
  isUpdateDeleteAccess }) => ([ ...[
  {
    header: 's-no',
    accessorKey: 'dailyId',
    cell: (value) => {
      const dailyId = value.row.index + 1;
     return dailyId;
    }
  },
  {
    header: 'grade-name',
    accessorKey: 'gradeName'
  },
  {
    header: 'hq-da-cost',
    accessorKey: 'hqDaCost'
  },
  {
    header: 'ex-hq-cost',
    accessorKey: 'exHqDaCost'
  },
  {
    header: 'valid-from',
    accessorKey: 'daValidFrom',
    cell: (cell) => {
      const value = cell.getValue();
      const formattedDate = dayjs(value).format('DD-MM-YYYY');
      return <span>{formattedDate}</span>;
    },
    meta: {
      className: 'cell-right'
    }
  },
  {
    header: 'valid-to',
  
    accessorKey: 'daValidTo',
    cell: (cell) => {
      const value = cell.getValue();
      const formattedDate = dayjs(value).format('DD-MM-YYYY');
      return <span>{formattedDate}</span>;
    },
    meta: {
      className: 'cell-right'
    }
  },
  {
    header: 'created-at',
    hideColumn: true,
    accessorKey: 'createdAt',
    cell: ({ getValue }) => dateString(getValue()) 
  },
  {
    header: 'created-by',
    hideColumn: true,
    accessorKey: 'createdBy'
  },
  {
    header: 'updated-at',
    hideColumn: true,
    accessorKey: 'updatedAt',
    cell: ({ getValue }) => dateString(getValue()) 
  },
  {
    header: 'updated-by',
    hideColumn: true,
    accessorKey: 'updatedBy'
  },
  {
    header: 'status',
    accessorKey: 'activeStatus',
    cell: (cell) => {
      switch (cell.getValue()) {
        case '0':
          return <Chip color="error" label="In-Active" size="small" variant="light" />;
        case '1':
          return <Chip color="success" label="Active" size="small" variant="light" />;

      }
    }
  }
], ...(isUpdateDeleteAccess ? [
  {
    header: 'action',
    id: "edit",
    cell: (props) => (
      <Grid container className="table-icon-actions-2">
         <VerifyAccess requestedMenu={allowanceTabMasterKey} requestedFeature={updateKey}>
        <Grid item>
          <EditAction
            {...props}
            onRowEdit={onRowEdit}
          />
        </Grid>
        </VerifyAccess>
        <VerifyAccess requestedMenu={allowanceTabMasterKey} requestedFeature={deleteKey}>
        <Grid item>
          <DeleteAction
            {...props}
            onRowDelete={onRowDelete} deleteAllowanceMasterStatus={deleteAllowanceMasterStatus}
          />
        </Grid>
        </VerifyAccess>
      </Grid>
    ),
    meta: {
      className: 'cell-center'
    }
  }
]: [])]);

export const reportFileName = "Allowance Master";

