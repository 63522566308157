import { useEffect } from "react";

const useSessionTimeout = (timeoutDuration, logout) => {
  useEffect(() => {
    let timeout;

    // Function to reset the timer
    const resetTimer = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        logout(); // Call logout function after timeout
      }, timeoutDuration);
    };

    // List of user activity events
    const events = ["mousemove", "keydown", "click", "scroll"];

    // Attach event listeners
    events.forEach((event) => window.addEventListener(event, resetTimer));

    resetTimer(); // Start timer initially

    return () => {
      // Cleanup event listeners and timeout on unmount
      events.forEach((event) => window.removeEventListener(event, resetTimer));
      clearTimeout(timeout);
    };
  }, [timeoutDuration, logout]);
};

export default useSessionTimeout;
