import { useFormik } from "formik";
import * as yup from 'yup';
import { useCallback, useMemo, useEffect } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { getData, getErrorMessage, getStatus } from "@/store/slices/helpers/helpers";
import { fetchData, clearMultipleData } from "@/store/slices/fetch-slice";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "@/store/api/constants";
import { initialValues } from "../constants/constants";
import { getFormattedPayload, getFormattedResponse } from "@/common/helpers/helpers";
function useCreateEditSecCustomerMaster({activeEditId,}) {

  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const state = useSelector((store) => store);

 
  const validationSchema = yup.object({
    secCusCode: yup.string().nullable(formatMessage({ id: "this-field-is-required" })),
    secCusName: yup.string().nullable(formatMessage({ id: "this-field-is-required" })),
    secCusGstNo: yup.string().nullable(formatMessage({ id: "this-field-is-required" })),
    address: yup.string().nullable(formatMessage({ id: "this-field-is-required" })),
    landmark: yup.string().nullable(formatMessage({ id: "this-field-is-required" })),
    latitude: yup.string().nullable(formatMessage({ id: "this-field-is-required" })),
    contactPerson: yup.string().nullable(formatMessage({ id: "this-field-is-required" })),
    contactNo: yup.string().nullable(formatMessage({ id: "this-field-is-required" })),
    longitude: yup.string().nullable(formatMessage({ id: "this-field-is-required" })),
    secCusCategory: yup.object().nullable(formatMessage({ id: "this-field-is-required" })),
    subzoneId: yup.object().nullable(formatMessage({ id: "this-field-is-required" })),
    cityId: yup.object().nullable(formatMessage({ id: "this-field-is-required" })),
    districtId: yup.object().nullable(formatMessage({ id: "this-field-is-required" })),
    activeStatus: yup.boolean().nullable(formatMessage({ id: "this-field-is-required" })),
  });

  const saveSecCustomerMasterStatus = getStatus(state, apiKeys.saveSecCustomerMaster, '');
  const updateSecCustomerMasterStatus = getStatus(state, apiKeys.updateSecCustomerMaster, '');
  const saveSecCustomerMasterLoading = apiStatuses.pending === saveSecCustomerMasterStatus;
  const getEditSecCustomerMasterData = getData(state, apiKeys.getEditSecCustomerMaster, {});
  const getEditSecCustomerMasterStatus = getStatus(state, apiKeys.getEditSecCustomerMaster, "");
  const getEditSecCustomerMasterErrMsg = getErrorMessage(
    state,
    apiKeys.getEditSecCustomerMaster,
    formatMessage({ id: "failed-to-get-selected-subzone-details" })
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if(activeEditId){
        updateSecCustomerMaster(values)
      }else{
        saveSecCustomerMaster(values)
      }
    }
  });

  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;

  const getEditSecCustomerMaster = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getEditSecCustomerMaster(activeEditId),
        method: apiMethods.GET,
        key: apiKeys.getEditSecCustomerMaster,
        failureMsg: formatMessage({ id: "failed-to-get-selected-secondary-customer" }),
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(() => {
    if (activeEditId) {
      getEditSecCustomerMaster()
    }
  }, [activeEditId, getEditSecCustomerMaster]);

  useEffect(() => {
    if (getEditSecCustomerMasterStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getEditSecCustomerMasterData))
    }
  }, [getEditSecCustomerMasterStatus])

  const saveSecCustomerMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.saveSecCustomerMaster,
        method: apiMethods.POST,
        key: apiKeys.saveSecCustomerMaster,
        successMsg: formatMessage({ id: "secondary-customer-is-saved-successfully" }),
        failureMsg: formatMessage({ id:  "secondary-customer-is-failed-to-save" }),
        body: getFormattedPayload(values)
      })
    );
  }, [dispatch, formatMessage]);

  const updateSecCustomerMaster = useCallback((values) => {
    dispatch(
      fetchData({
        url: apiUrls.updateSecCustomerMaster,
        method: apiMethods.PUT,
        key: apiKeys.updateSecCustomerMaster,
        successMsg: formatMessage({ id: "secondary-customer-is-updated-successfully" }),
        failureMsg: formatMessage({ id: "secondary-customer-is-failed-to-update" }),
        body: getFormattedPayload({ ...values, id: activeEditId })
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  // clear state
  useEffect(() => () => {
    dispatch(clearMultipleData({ key: [apiKeys.saveSecCustomerMaster, 
      apiKeys.getEditSecCustomerMaster] }));
  }, [dispatch])




  return useMemo(() => ({
    formik,
    values: formik.values,
    saveSecCustomerMasterStatus,
    updateSecCustomerMasterStatus,
    saveSecCustomerMasterLoading,
    getEditSecCustomerMasterData,
    getEditSecCustomerMasterStatus,
    getEditSecCustomerMasterErrMsg,
  }), [
    formik,
    saveSecCustomerMasterStatus,
    updateSecCustomerMasterStatus,
    saveSecCustomerMasterLoading,
    getEditSecCustomerMasterData,
    getEditSecCustomerMasterStatus,
    getEditSecCustomerMasterErrMsg,
  ])
}

export default useCreateEditSecCustomerMaster