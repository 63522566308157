import { 
    Dialog, DialogTitle, Grid,
    AppBar,
    Button,
    Toolbar,
    Typography
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import IconButton from '@/from-theme/components/@extended/IconButton';
import { CloseOutlined } from '@ant-design/icons';

export default function ModalDialog({
    closeModal,
    scroll = "paper",
    modalTitle,
    children,
    maxWidth = "md",
    fullScreen = false,
}) {
    return (
        <Dialog
            open
            fullScreen={fullScreen}
            onClose={closeModal}
            scroll={scroll}
            maxWidth={maxWidth}
            PaperProps={{
                sx: { 
                    borderRadius: fullScreen ? 0 : '25px',
                    overflow: fullScreen ? 'scroll' : 'visible', 
                } 
            }}
        >
            {fullScreen ? 
                (<>
                    <AppBar sx={{ position: 'relative', boxShadow: 'none' }}>
                        <Toolbar>
                            <IconButton
                                edge="start" color="inherit" onClick={closeModal}
                                aria-label="close"
                            >
                            <CloseOutlined />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            <FormattedMessage id={modalTitle} />
                            </Typography>
                            <Button color="primary" variant="contained" onClick={closeModal}>
                            Close </Button>
                        </Toolbar>
                    </AppBar>
                </>)
                :
                (
                    <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <DialogTitle>
                                <FormattedMessage id={modalTitle} />
                            </DialogTitle>
                        </Grid>
                        <Grid item sx={{ mr: 1.5 }}>
                            <IconButton color="secondary" onClick={closeModal}>
                                <CloseOutlined />
                            </IconButton>
                        </Grid>
                    </Grid>
                )
            }
            {children}
        </Dialog>
    )
}
