import React from "react";
import {
  Button, DialogActions, DialogContent, Grid
} from '@mui/material';
import TextInput from "@/common/components/text-input/text-input";
import SwitchInput from "@/common/components/switch-input/switch-input";
import AnimateButton from "@/from-theme/components/@extended/AnimateButton";
import { FormattedMessage } from 'react-intl';
import useCreateEditDropdownMaster from "./hooks/useCreateEditDropdownMaster";
import PropTypes from "prop-types";
import { apiStatuses } from "@/store/api/constants";
import Spinner from "@/common/components/spinner/spinner";
import ErrorInPage from "@/common/components/error-in-page/error-in-page";


function CreateEditDropdownMaster({ closeModal, activeEditId }) {
  const {
    values, formik, getEditDropdownMasterStatus,
    getEditDropdownMasterErrMsg, saveDropdownMasterStatus, updateDropdownMasterStatus,
  } = useCreateEditDropdownMaster(activeEditId);

  const isPending = [saveDropdownMasterStatus, updateDropdownMasterStatus]
  .includes(apiStatuses.pending);

  const { dropdownName, ddShortName, ddShortCode, activeStatus } = values;

  return (
    <>
      {
        getEditDropdownMasterStatus === apiStatuses.pending && activeEditId
        && <Spinner />
      }
      {getEditDropdownMasterStatus === apiStatuses.rejected && activeEditId && (
        <ErrorInPage message={getEditDropdownMasterErrMsg} />
      )}
      {(getEditDropdownMasterStatus === apiStatuses.succeeded || !activeEditId) &&
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <Grid container spacing={1.25}>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="dropdown-name" size="small" fullWidth
                  value={dropdownName}
                  inputLabel="dropdown-name"
                  formik={formik}
                  name="dropdownName"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="dropdown-short-name" size="small" fullWidth
                  value={ddShortName}
                  inputLabel="dropdown-short-name"
                  formik={formik}
                  name="ddShortName"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                <TextInput
                  placeholder="dropdown-short-code" size="small" fullWidth
                  value={ddShortCode}
                  inputLabel="dropdown-short-code"
                  formik={formik}
                  name="ddShortCode"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <SwitchInput
                  inputLabel="activeStatus"
                  leftLabel="inactive"
                  rightLabel="active"
                  value={activeStatus}
                  formik={formik}
                  name="activeStatus"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={closeModal} variant="outlined" disabled={isPending} 
             sx={{ mr: 1 }}>
              <FormattedMessage id={"cancel"} />
            </Button>
            <AnimateButton>
              <Button type="submit" variant="contained" disabled={isPending}  sx={{ mr: 1 }}>
                <FormattedMessage id={"save"} />
              </Button>
            </AnimateButton>
          </DialogActions>
        </form>
      }
    </>
  )
}

CreateEditDropdownMaster.propTypes = {
  closeModal: PropTypes.func.isRequired,
  activeEditId: PropTypes.bool,
};

CreateEditDropdownMaster.defaultProps = {
  activeEditId: 0,
};

export default CreateEditDropdownMaster;